import React, { createContext, useState, useContext, useEffect } from "react";
import supabase from "../SupabaseClient";
import { UserContext } from "./UserContext";
import { CircularProgress } from "@mui/material";
import ChatRoom from "./Chat/ChatRoom";

interface DirectMessage {
  chat_id: string; // Identifier for the chat room
  other_user_id: string; // ID of the other user in the chat
  other_user_avatar_url: string; // Avatar URL of the other user in the chat
  other_username: string; // Username of the other user in the chat
  last_message: string; // Text of the last message in the chat
  last_message_time: string; // Timestamp of the last message
  current_user_id: string; // ID of the current user
  current_username: string; // Username of the current user
}

export interface ChatRoom {
  id: string;
  course_code: string;
}

interface CombinedChatData {
  courseChatRooms: ChatRoom[];
  directChatRooms: DirectMessage[];
  loadingChats: boolean;
  errorChats: string | null;
  softDeleteConversation: (chatId: string) => Promise<void>;
  fetchDirectChatRooms: () => Promise<void>;
  isChatRoomForUser: (chatRoomId: string) => boolean;
}

export const SupabaseChatRoomContext = createContext<CombinedChatData | null>(
  null,
);

export const useSupabaseChatRoom = () => {
  const context = useContext(SupabaseChatRoomContext);
  if (!context) {
    throw new Error(
      "useSupabaseChatRoom must be used within a SupabaseChatProvider",
    );
  }
  return context;
};

export const SupabaseChatRoomProvider: React.FC = ({ children }) => {
  const [courseChatRooms, setCourseChatRooms] = useState<ChatRoom[]>([]);
  const [directChatRooms, setDirectChatRooms] = useState<DirectMessage[]>([]);
  const [loadingChats, setLoadingChats] = useState<boolean>(true);
  const [errorChats, setErrorChats] = useState<string | null>(null);
  const userContext = useContext(UserContext);

  if (!userContext) {
    return <CircularProgress />;
  }

  const { userId } = userContext;

  useEffect(() => {
    const fetchChatData = async () => {
      if (!userId) return;

      try {
        setLoadingChats(true);

        // Fetch student's enrolled courses
        const { data: studentCoursesData, error: studentCoursesError } =
          await supabase
            .from("student_courses")
            .select("course_id")
            .eq("student_id", userId);

        if (studentCoursesError) throw studentCoursesError;

        const courseIds = studentCoursesData.map((row) => row.course_id);

        // Fetch course chat rooms
        const fetchCourseChatRooms = await supabase
          .from("chat")
          .select("*")
          .in("student_course_course_id", courseIds)
          .neq("course_code", null);

        // Fetch direct messages using the RPC call
        const { data: directMessagesData, error: directMessagesError } =
          await supabase.rpc("get_direct_messages_for_user", {
            user_id: userId,
          });

        if (directMessagesError) throw directMessagesError;
        // Execute both queries concurrently
        const [courseChatResponse] = await Promise.all([fetchCourseChatRooms]);
        if (courseChatResponse.error) throw courseChatResponse.error;
        setDirectChatRooms(directMessagesData); // Update the state with the data received from the RPC call
        setCourseChatRooms(courseChatResponse.data); // Update the state with the data received from the query
      } catch (error) {
        if (error instanceof Error) {
          setErrorChats(error.message);
        } else {
          setErrorChats("An unknown error occurred");
        }
      } finally {
        setLoadingChats(false);
      }
    };

    fetchChatData();
  }, [userId]);

  const fetchDirectChatRooms = async () => {
    try {
      const { data: directMessagesData, error: directMessagesError } =
        await supabase.rpc("get_direct_messages_for_user", {
          user_id: userId,
        });

      if (directMessagesError) throw directMessagesError;

      setDirectChatRooms(directMessagesData);
    } catch (error) {
      console.error("Error fetching direct chat rooms:", error);
    }
  };

  const softDeleteConversation = async (chatId: string) => {
    try {
      // Here you would call your RPC function, e.g.:
      const { error } = await supabase.rpc("soft_delete_direct_message", {
        param_chat_id: chatId,
        param_user_id: userId,
      });

      if (error) throw error;

      // Refresh the direct messages list
      await fetchDirectChatRooms();
    } catch (error) {
      console.error("Error in soft-deleting the conversation:", error);
    }
  };

  // Function to check if a chat room is relevant for the user
  const isChatRoomForUser = (chatRoomId: string) => {
    const isDirectChatRoom = directChatRooms.some(
      (room) => room.chat_id === chatRoomId,
    );
    const isCourseChatRoom = courseChatRooms.some(
      (room) => room.id === chatRoomId,
    );
    return isDirectChatRoom || isCourseChatRoom;
  };

  return (
    <SupabaseChatRoomContext.Provider
      value={{
        courseChatRooms,
        directChatRooms,
        loadingChats,
        errorChats,
        isChatRoomForUser,
        softDeleteConversation,
        fetchDirectChatRooms,
      }}
    >
      {children}
    </SupabaseChatRoomContext.Provider>
  );
};
