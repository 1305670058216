import React from "react";
import {
  EventApi,
  EventInput,
  DateSelectArg,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import supabase from "../../SupabaseClient";
import EventModal from "../CommonComponents/EventModal";

interface CalendarState {
  weekendsVisible: boolean;
  currentEvents: EventInput[];
  userId?: string;
  initialStart: string;
  initialEnd: string;
  isModalOpen: boolean;
  selectedEvent: EventInput | null;
}

export default class Calendar extends React.Component<object, CalendarState> {
  state: CalendarState = {
    weekendsVisible: true,
    currentEvents: [],
    userId: undefined,
    initialStart: "",
    initialEnd: "",
    isModalOpen: false,
    selectedEvent: null,
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };
  render() {
    return (
      <div>
        <div>
          <FullCalendar
            titleFormat={{ month: "long", year: "numeric" }} // add this line for month display
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            aspectRatio={1.7}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={this.state.weekendsVisible}
            events={this.state.currentEvents} // Updated this line to use state for events
            select={this.handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            eventsSet={this.handleEvents}
            // called after events are initialized/added/changed/removed
            /* You can update a remote database when these fire:
          eventChange={function(){}}
          eventRemove={function(){}}
          */
          />
          <EventModal
            isOpen={this.state.isModalOpen}
            onClose={this.closeModal}
            onSubmit={this.handleFormSubmit}
            onDelete={this.handleDelete}
            initialStart={this.state.initialStart} // Pass the selected start date
            initialEnd={this.state.initialEnd} // Pass the selected end date
            selectedEvent={this.state.selectedEvent}
          />
        </div>
      </div>
    );
  }

  renderSidebar() {
    return (
      <div className="demo-app-sidebar">
        <div className="demo-app-sidebar-section">
          {/* <label>
            <input
              type="checkbox"
              checked={this.state.weekendsVisible}
              onChange={this.handleWeekendsToggle}
            ></input>
            Toggle Weekends
          </label> */}
        </div>
        <div className="demo-app-sidebar-section">
          {/* <h2>All Events ({this.state.currentEvents.length})</h2> */}
          {/* <ul>{this.state.currentEvents.map(renderSidebarEvent)}</ul> */}
        </div>
      </div>
    );
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible,
    });
  };

  componentDidMount() {
    this.fetchUserId().then((userId) => {
      if (userId) {
        this.fetchEvents(userId); // pass userId to fetchEvents
      }
    });
  }
  async fetchUserId() {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    const userId = user?.id;
    if (userId) {
      this.setState({ userId });
      return userId;
    }
  }

  handleDateSelect = (selectInfo: DateSelectArg) => {
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection

    if (selectInfo.allDay) {
      // If all day is selected, adjust the start and end times
      const start = new Date(selectInfo.start);
      const end = new Date(selectInfo.start); // Use the same start date for the end

      // Set start time to 12:00 AM
      start.setHours(10, 30, 0, 0);
      // Set end time to 11:59 PMs
      end.setHours(12, 0, 0, 0);

      this.setState({
        initialStart: start.toISOString(),
        initialEnd: end.toISOString(),
        isModalOpen: true,
        selectedEvent: null,
      });
    } else {
      // For non-all-day events, use the selected times
      this.setState({
        initialStart: selectInfo.startStr,
        initialEnd: selectInfo.endStr,
        isModalOpen: true,
        selectedEvent: null,
      });
    }
  };

  handleFormSubmit = (eventData: {
    title: string;
    start: string;
    end: string;
    location: string;
    type: string;
    allDay: boolean;
  }) => {
    this.addEventToDatabase(eventData);
    this.closeModal();
  };

  async fetchEvents(userId: string) {
    if (userId) {
      // Ensure user is authenticated
      try {
        const { data, error } = await supabase
          .from("events")
          .select("*")
          .eq("user_id", userId); // Assuming user_id is the foreign key

        if (error) {
          console.error("Error fetching events:", error.message);
        } else {
          const formattedEvents = data.map((dbEvent) => {
            return {
              id: dbEvent.id,
              title: dbEvent.title,
              start: dbEvent.start,
              end: dbEvent.end,
              allDay: dbEvent.all_day,
              type: dbEvent.type,
              location: dbEvent.location,
            };
          });
          this.setState({ currentEvents: formattedEvents });
        }
      } catch (error) {
        console.error("Unexpected error fetching events:", error);
      }
    }
  }

  handleDelete = async (eventId: string) => {
    try {
      const { error } = await supabase
        .from("events")
        .delete()
        .eq("id", eventId);

      if (error) {
        console.error("Error deleting event:", error.message);
      } else {
        this.setState((prevState) => ({
          currentEvents: prevState.currentEvents.filter(
            (event) => event.id !== eventId,
          ),
          isModalOpen: false,
          selectedEvent: null,
        }));
      }
    } catch (error) {
      console.error("Unexpected error deleting event:", error);
    }
  };

  addEventToDatabase = async (eventData: {
    title: string;
    start: string;
    end: string;
    location: string;
    type: string;
    allDay: boolean;
  }) => {
    const { title, start, end, location, type, allDay } = eventData;
    const newEvent = {
      title,
      start,
      end,
      location,
      type,
      allDay,
      id: Math.random().toString(),
    };

    this.setState((prevState) => ({
      currentEvents: [...prevState.currentEvents, newEvent],
    }));

    try {
      const { error } = await supabase.from("events").insert([
        {
          title,
          start,
          end,
          all_day: allDay,
          user_id: this.state.userId,
          location: location,
          type: type,
        },
      ]);

      if (error) {
        console.error("Error adding event:", error.message);
        this.setState((prevState) => ({
          currentEvents: prevState.currentEvents.filter(
            (event) => event.id !== newEvent.id,
          ),
        }));
      } else {
        const { data, error } = await supabase
          .from("events")
          .select("*")
          .eq("user_id", this.state.userId)
          .order("created_at", { ascending: false })
          .limit(1);

        if (error) {
          console.error("Error fetching the latest event:", error.message);
        } else {
          if (data) {
            const realEvent = data[0];

            // Find the event with the temp ID and update its ID with the real ID
            this.setState((prevState) => ({
              currentEvents: prevState.currentEvents.map((event) =>
                event.id === newEvent.id
                  ? { ...event, id: realEvent.id }
                  : event,
              ),
            }));
          }
        }
      }
    } catch (error) {
      console.error("Unexpected error adding event:", error);
    }
  };

  handleEventClick = (clickInfo: EventClickArg) => {
    if (clickInfo.event.id) {
      // Create a new event object for the selectedEvent state
      const eventToUpdate = clickInfo.event.toPlainObject();

      // If the event is all-day and the end date is not set, use the start date as the end date
      if (eventToUpdate.allDay && !eventToUpdate.end) {
        eventToUpdate.end = eventToUpdate.start;
      }

      this.setState({
        selectedEvent: eventToUpdate,
        isModalOpen: true,
      });
    } else {
      alert("This event does not have an associated ID!");
    }
  };

  handleEvents = (events: EventApi[]) => {
    const mappedEvents = events.map((eventApi) => {
      return {
        id: eventApi.id,
        title: eventApi.title,
        start: eventApi.start ? eventApi.start.toISOString() : "", // handle null values
        end: eventApi.end ? eventApi.end.toISOString() : "", // handle null values
        allDay: eventApi.allDay,
        type: eventApi.extendedProps.type,
        location: eventApi.extendedProps.location,
        // ... any other necessary properties
      };
    });

    // Check if events have actually changed
    if (
      JSON.stringify(this.state.currentEvents) !== JSON.stringify(mappedEvents)
    ) {
      this.setState({
        currentEvents: mappedEvents,
      });
    }
  };
}

function renderEventContent(eventContent: EventContentArg) {
  return (
    <>
      <b>{eventContent.timeText}</b>
      <i>{eventContent.event.title}</i>
    </>
  );
}
