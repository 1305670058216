// ChatRoomList.tsx
import React, { useState, useEffect, memo, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSupabaseChatRoom } from "../SupabaseChatRoomContext";
import { UserContext } from "../UserContext";
import { CircularProgress } from "@material-ui/core";
import { AiFillDelete, AiOutlineUser } from "react-icons/ai";
import { FaRobot } from "react-icons/fa";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import UserSearch from "../CommonComponents/UserSearch";
import supabase from "../../SupabaseClient";
import { useMessageNotification } from "../MessageNotificationContext";

interface ChatRoomListProps {
  onChatroomSelect: (
    id: string,
    otherUsername?: string,
    otherUserId?: string,
    otherUserAvatarUrl?: string,
  ) => void;

  darkMode: boolean;
}

const AI_TUTOR_CHATROOM_ID = "tutor";

const ChatRoomList: React.FC<ChatRoomListProps> = memo(
  ({ onChatroomSelect, darkMode }) => {
    const navigate = useNavigate();
    const [selectedChatId, setSelectedChatId] = useState<string | null>(null);
    const params = useParams<{ chatId?: string }>();
    const routeChatId = params.chatId;
    const [isCourseListCollapsed, setIsCourseListCollapsed] = useState(false);
    const [isDirectMessageListCollapsed, setIsDirectMessageListCollapsed] =
      useState(false);
    const [showScrollArrow, setShowScrollArrow] = useState(false);
    const userContext = useContext(UserContext);
    const chatContext = useSupabaseChatRoom();
    const { notifications } = useMessageNotification();

    if (!userContext) {
      console.error(
        "UserContext is null, this component must be rendered within a UserProvider",
      );
      return <CircularProgress />;
    }

    const {
      courseChatRooms,
      directChatRooms,
      loadingChats,
      errorChats,
      softDeleteConversation,
    } = useSupabaseChatRoom();

    const { userId, onlineUserCount } = userContext;

    const directMessagesRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (userContext) {
        userContext.fetchAndUpdateOnlineUserCount();
      }
    }, [userContext]);

    const handleScroll = () => {
      const current = directMessagesRef.current;
      if (current) {
        const atBottom =
          current.scrollHeight - current.scrollTop <= current.clientHeight + 1;
        setShowScrollArrow(!atBottom);
      }
    };

    const hasUnreadNotifications = (chatId: string) => {
      return notifications.some(
        (notification) => notification.chat_id === chatId && !notification.read,
      );
    };

    useEffect(() => {
      const checkOverflow = () => {
        const current = directMessagesRef.current;
        if (current) {
          setShowScrollArrow(current.scrollHeight > current.clientHeight);
        }
      };

      // Defer the check until after the browser has had a chance to paint
      const timer = setTimeout(checkOverflow, 0);

      return () => clearTimeout(timer); // Cleanup the timeout on component unmount
    }, [directChatRooms]); // Re-run this effect when directChatRooms changes

    if (errorChats) {
      return (
        <div className="mt-4 text-center text-red-600 text-md">
          Error fetching chat rooms: {errorChats}
        </div>
      );
    }

    const handleAIClick = () => {
      navigate(`/chat/${AI_TUTOR_CHATROOM_ID}`);
      setSelectedChatId(AI_TUTOR_CHATROOM_ID);
      onChatroomSelect(AI_TUTOR_CHATROOM_ID); // Pass the AI_TUTOR_CHATROOM_ID to parent
    };

    const toggleCourseList = () => {
      setIsCourseListCollapsed(!isCourseListCollapsed);
    };

    const toggleDirectMessageList = () => {
      setIsDirectMessageListCollapsed(!isDirectMessageListCollapsed);
    };

    // If the URL changes (e.g., user navigates directly via URL), update the selected chat room
    useEffect(() => {
      if (routeChatId && routeChatId !== selectedChatId) {
        setSelectedChatId(routeChatId);
        onChatroomSelect(routeChatId);
      }
    }, [courseChatRooms, routeChatId, onChatroomSelect, selectedChatId]);

    if (loadingChats) {
      return (
        <div className="flex items-center justify-center h-screen">
          <div className="w-32 h-32 border-b-2 border-gray-900 rounded-full animate-spin"></div>
        </div>
      );
    }
    const handleDelete = async (chatId: string) => {
      if (
        window.confirm("Are you sure you want to delete this conversation?")
      ) {
        await softDeleteConversation(chatId);

        if (chatId === selectedChatId) {
          // Reset the selected chat room
          setSelectedChatId(null);

          // Navigate to the first chat room in the list, if available
          if (courseChatRooms && courseChatRooms.length > 0) {
            const firstChatRoom = courseChatRooms[0];
            navigate(`/chat/${courseChatRooms[0].id}`);
            onChatroomSelect(firstChatRoom.id);
          } else {
            navigate("/chat"); // Navigate to a default or empty chat view
          }
        }
      }
    };

    // Render function for direct chat rooms
    const renderedDirectChatRooms = directChatRooms?.map((directChat) => {
      return (
        <li
          key={directChat.chat_id}
          className={`mb-4 flex justify-between items-center ${
            darkMode ? "text-white" : "text-gray-800"
          }`}
        >
          <button
            onClick={() => {
              navigate(`/chat/${directChat.chat_id}`);
              setSelectedChatId(directChat.chat_id);
              onChatroomSelect(
                directChat.chat_id,
                directChat.other_username,
                directChat.other_user_id,
                directChat.other_user_avatar_url,
              );
            }}
            className={`transition-colors duration-300 font-bold w-full py-2 rounded-2xl ${
              selectedChatId === directChat.chat_id
                ? "bg-black text-white"
                : "bg-transparent hover:bg-gray-200 dark:hover:bg-gray-600"
            }`}
          >
            {directChat.other_username}
            {hasUnreadNotifications(directChat.chat_id) && (
              <span className="inline-block w-2 h-2 ml-2 bg-red-500 rounded-full animate-pulse"></span>
            )}
          </button>
          <AiFillDelete
            onClick={() => handleDelete(directChat.chat_id)}
            className="text-xl cursor-pointer" // You can adjust the size as needed
          />
        </li>
      );
    });

    const confirmAndNavigate = async (
      selectedUserId: string,
      username: string,
    ) => {
      if (!userId) return;

      try {
        const { data, error } = await supabase.rpc(
          "get_or_create_direct_message_chatroom",
          {
            param_user_id_1: userId,
            param_user_id_2: selectedUserId,
          },
        );

        if (error) throw error;

        const chatroomData = data[0];
        const chatId = chatroomData.chatroom_id;
        const otherUsername = chatroomData.other_username;
        const otherUserId = chatroomData.other_user_id;
        const isExistingChat = chatroomData.is_existing;

        if (
          isExistingChat ||
          window.confirm(
            `Do you want to start a conversation with ${username}?`,
          )
        ) {
          navigate(`/chat/${chatId}`, {
            state: { otherUsername, otherUserId },
          });
          await chatContext?.fetchDirectChatRooms();

          if (!isExistingChat) {
            await chatContext?.fetchDirectChatRooms();
          }
        }
      } catch (error) {
        console.error("Error in getting or creating a chatroom:", error);
      }
    };

    return (
      <div className="flex flex-col justify-between h-full p-2 bg-gray-100 dark:bg-gray-800">
        <div className="mt-0">
          <UserSearch
            onUserSelect={(userId, username) =>
              confirmAndNavigate(userId, username)
            }
          />

          <h2
            onClick={toggleCourseList}
            className={`mb-4 text-lg font-semibold text-center cursor-pointer ${"text-white"} flex items-center justify-start bg-gray-600 p-2 rounded-2xl`}
          >
            {isCourseListCollapsed ? (
              <FaChevronDown className="mr-2" />
            ) : (
              <FaChevronUp className="mr-2" />
            )}
            Course's Chat
          </h2>
          {!isCourseListCollapsed && (
            <ul className="space-y-2 overflow-y-auto max-h-36">
              {courseChatRooms?.map((room) => (
                <li
                  key={room.id}
                  className={`mb-4 text-md font-semibold text-center ${
                    darkMode ? "text-white" : "text-gray-800"
                  }`}
                >
                  <button
                    onClick={() => {
                      navigate(`/chat/${room.id}`);
                      setSelectedChatId(room.id);
                      onChatroomSelect(room.id);
                    }}
                    className={`transition-colors duration-300 font-bold w-full py-2 rounded-2xl ${
                      selectedChatId === room.id
                        ? darkMode
                          ? "bg-black text-white"
                          : "bg-black text-white"
                        : "bg-transparent hover:bg-gray-200 dark:hover:bg-gray-600"
                    }`}
                  >
                    {room.course_code}
                    {hasUnreadNotifications(room.id) && (
                      <span className="inline-block w-2 h-2 ml-2 bg-red-500 rounded-full animate-pulse"></span>
                    )}
                  </button>
                </li>
              ))}
            </ul>
          )}
          {/* Direct Messages Section */}
          <h2
            onClick={toggleDirectMessageList}
            className={`my-6 text-lg font-semibold text-center cursor-pointer ${"text-white"} flex items-center justify-start bg-gray-600 p-2 rounded-2xl`}
          >
            {isDirectMessageListCollapsed ? (
              <FaChevronDown className="mr-2" />
            ) : (
              <FaChevronUp className="mr-2" />
            )}
            Direct Messages
          </h2>
          {!isDirectMessageListCollapsed && (
            <div
              ref={directMessagesRef}
              onScroll={handleScroll}
              className="mb-4 overflow-y-auto max-h-72 scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-800"
            >
              <ul className="space-y-2">{renderedDirectChatRooms}</ul>
            </div>
          )}

          {/* Scroll Arrow Indicator */}
          {showScrollArrow && (
            <div className="pb-2 text-center">
              <FaChevronDown className="text-xl text-gray-600 animate-bounce" />
            </div>
          )}
        </div>

        <div>
          {/* Assistant Section */}

          <h2
            className={`text-lg font-semibold text-center ${"text-white"} bg-gray-600 p-2 rounded-2xl flex items-center justify-start mb-4`}
          >
            <FaRobot className="mr-2" /> {/* Assistant icon */}
            Assistant
          </h2>

          <button
            onClick={handleAIClick}
            className={`transition-colors duration-300 font-bold w-full py-2 rounded-2xl text-md text-center mb-4 ${
              selectedChatId === AI_TUTOR_CHATROOM_ID
                ? "bg-black text-white" // Ensures text is white when selected
                : `hover:bg-gray-200 dark:hover:bg-gray-600 ${
                    darkMode ? "text-white" : "text-gray-800"
                  }` // Ensures text is white or gray depending on dark mode when not selected
            }`}
          >
            Brainy
          </button>
        </div>

        {/* Overall Online Users Section */}
        <div className="mt-4 text-center">
          <div className="inline-flex items-center px-4 py-2 text-white bg-green-500 rounded-full animate-pulse">
            {/* Loader can be added here */}
            <AiOutlineUser className="mr-2 text-white text-md" /> Total Students
            Online: {onlineUserCount}
          </div>
        </div>
      </div>
    );
  },
);

export default ChatRoomList;
