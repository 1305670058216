import React, { useContext, useEffect, useState } from "react";
import { supabase } from "../../SupabaseClient";
import { RxPerson } from "react-icons/rx";
import { PiChatsCircleLight } from "react-icons/pi";
import { MdLogout } from "react-icons/md";
import { SlHome } from "react-icons/sl";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { UserContext } from "../UserContext";
import uprLogo from "../../assets/logo512.png";
import { IoNotificationsOutline } from "react-icons/io5";
import { useMessageNotification } from "../MessageNotificationContext";
import defaultProfile from "../../assets/default_profile.jpeg";

interface Notification {
  notification_id: string;
  chat_id: string;
  sender_id: string;
  msg_timestamp: string;
  message_text: string;
  sender_username?: string;
  sender_avatar_url?: string;
  course_code?: string;
  read: boolean;
}

const Navbar: React.FC = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [universityColorClass, setUniversityColorClass] = useState(
    localStorage.getItem("universityColorClass") || "bg-primary",
  );
  // State to manage the hamburger menu visibility
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const { notifications, markNotificationAsRead, markMessageAsRead } =
    useMessageNotification();

  if (!userContext) {
    console.error(
      "UserContext is null, this component must be rendered within a UserProvider",
    );
    return <CircularProgress />;
  }

  const { userId, updateLastActivity } = userContext;

  useEffect(() => {
    if (userContext?.userUniversity) {
      const university: string = userContext.userUniversity.university;
      const colorClass = universityClassNames[university] || "bg-primary";
      if (colorClass !== universityColorClass) {
        localStorage.setItem("universityColorClass", colorClass);
        setUniversityColorClass(colorClass);
      }
    }
  }, [userContext, universityColorClass]);

  const universityClassNames: { [key: string]: string } = {
    UPRM: "bg-mayaguez",
    UPRRP: "bg-rioPiedras",
    UPRB: "bg-bayamon",
    UPRAG: "bg-aguadilla",
    UPRA: "bg-arecibo",
    UPRCA: "bg-carolina",
    UPRC: "bg-cayey",
    UPRH: "bg-humacao",
    UPRP: "bg-ponce",
    UPRU: "bg-utuado",
  };

  const navigateWithActivityUpdate = (path: string) => {
    updateLastActivity();
    navigate(path);
  };

  // Function to handle navigation to the homepage
  const navigateToHome = () => {
    navigate("/homepage");
  };

  const handleLogout = async () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    if (confirmLogout) {
      if (userId) {
        await setOnlineStatus(userId, false);
      }
      await supabase.auth.signOut();
      localStorage.removeItem("userId");
      localStorage.removeItem("darkMode");
      navigate("/welcome");
    }
  };

  const setOnlineStatus = async (userId: string, status: boolean) => {
    try {
      const { error } = await supabase.rpc("set_user_online_status", {
        user_id: userId,
        online_status: status,
      });

      if (error) {
        console.error("Error updating online status:", error);
      }
    } catch (err) {
      console.error("Error calling RPC:", err);
    }
  };

  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  // Function to handle notification click
  const handleNotificationClick = (notificationId: string, chatId: string) => {
    markNotificationAsRead(notificationId);
    markMessageAsRead(notificationId);
    navigate(`/chat/${chatId}`);
    setIsNotificationsOpen(false); // Close the dropdown after click
  };

  // Sort notifications by timestamp and filter out the read notifications
  const sortedUnreadNotifications = notifications
    .filter((notification) => !notification.read)
    .sort(
      (a, b) =>
        new Date(b.msg_timestamp).getTime() -
        new Date(a.msg_timestamp).getTime(),
    );

  const unreadNotificationCount = sortedUnreadNotifications.length;

  // Function to format the date in AST (Atlantic Standard Time)
  const convertUtcToAst = (utcDate: string): string => {
    const date = new Date(utcDate);
    date.setHours(date.getHours() - 4); // Adjust for AST, which is UTC-4
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "America/Puerto_Rico",
    });
  };

  const NotificationItem: React.FC<{ notification: Notification }> = ({
    notification,
  }) => {
    const avatarUrl = notification.sender_avatar_url || defaultProfile;
    return (
      <div className="flex items-start p-2 space-x-3 border-b border-gray-200 dark:border-gray-700">
        <img
          src={avatarUrl} // Use the avatarUrl variable here
          alt="Avatar"
          className="w-8 h-8 rounded-full"
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            // If there's an error loading the image, such as a broken URL, fallback to the default profile image
            e.currentTarget.src = defaultProfile;
          }}
        />
        <div className="flex-1 min-w-0">
          <strong className="font-semibold text-md">
            {notification.sender_username}
            {notification.course_code && (
              <span className="ml-2 text-xs font-normal text-gray-500">
                ({notification.course_code})
              </span>
            )}
          </strong>
          <p className="text-sm text-gray-600 dark:text-gray-500">
            {notification.message_text}
          </p>
          <p className="text-xs text-gray-500">
            {convertUtcToAst(notification.msg_timestamp)}
          </p>
        </div>
        <div className="self-center flex-shrink-0">
          <span className="block w-3 h-3 bg-red-500 rounded-full animate-pulse"></span>
        </div>
      </div>
    );
  };

  return (
    <nav
      className={`flex items-center justify-between p-2 ${universityColorClass}`}
    >
      {/* Logo and Brand Name */}
      <div
        className="flex items-center text-white cursor-pointer"
        onClick={navigateToHome}
      >
        <img src={uprLogo} alt="Logo" className="mr-2 w-14 h-14 " />
        <span className="hidden text-xl font-semibold tracking-tight sm:block">
          UPRConnect
        </span>
      </div>

      {/* Navigation Icons and Links */}
      <div className="items-center justify-end flex-grow hidden sm:flex">
        {/* Home */}
        <button
          onClick={() => navigateWithActivityUpdate("/homepage")}
          className="flex items-center p-2"
        >
          <SlHome className="mr-2 text-xl text-white" />
          <span className="text-sm text-white">Home</span>
        </button>

        {/* Chats */}
        <button
          onClick={() => navigateWithActivityUpdate("/chat/first")}
          className="flex items-center p-2"
        >
          <PiChatsCircleLight className="mr-2 text-xl text-white" />
          <span className="text-sm text-white">Chats</span>
        </button>

        {/* Profile */}
        <button
          onClick={() => navigateWithActivityUpdate("/profile")}
          className="flex items-center p-2"
        >
          <RxPerson className="mr-2 text-xl text-white" />
          <span className="text-sm text-white">Profile</span>
        </button>

        {/* Search */}
        <button
          onClick={() => navigateWithActivityUpdate("/search")}
          className="flex items-center p-2"
        >
          <FiSearch className="mr-2 text-xl text-white" />
          <span className="text-sm text-white">Search</span>
        </button>

        {/* Notifications */}
        <div className="relative p-2">
          <button onClick={toggleNotifications} className="flex items-center">
            <IoNotificationsOutline className="mr-2 text-xl text-white" />
            {unreadNotificationCount > 0 && (
              <span className="absolute flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 rounded-full right-1 -top-1">
                {unreadNotificationCount}
              </span>
            )}
            <span className="text-sm text-white">Notifications</span>
          </button>
          {/* Notifications Dropdown */}
          {isNotificationsOpen && (
            <div className="absolute z-50 py-2 mt-2 overflow-y-auto bg-white rounded-md shadow-lg w-80 max-h-96 right-4 top-full">
              {sortedUnreadNotifications.length === 0 ? (
                <div className="px-4 py-2 text-sm text-gray-500">
                  No new notifications
                </div>
              ) : (
                sortedUnreadNotifications.map((notification) => (
                  <a
                    key={notification.notification_id}
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNotificationClick(
                        notification.notification_id,
                        notification.chat_id,
                      );
                    }}
                  >
                    <NotificationItem
                      key={notification.notification_id}
                      notification={notification}
                    />
                  </a>
                ))
              )}
            </div>
          )}
        </div>

        {/* Logout */}
        <button
          onClick={handleLogout}
          className="inline-block px-2 py-1 mt-4 text-sm text-white border border-white rounded hover:border-transparent lg:mt-0"
        >
          <MdLogout className="inline-block mr-2" />
          <span className="text-sm text-white">Logout</span>
        </button>
      </div>

      {/* Mobile Icons */}
      <div className="flex justify-around flex-grow sm:hidden">
        {/* Icons for mobile */}
        <button
          onClick={() => navigateWithActivityUpdate("/homepage")}
          className="p-2"
        >
          <SlHome className="text-xl text-white" />
        </button>
        <button
          onClick={() => navigateWithActivityUpdate("/chat/first")}
          className="p-2"
        >
          <PiChatsCircleLight className="text-xl text-white" />
        </button>
        <button
          onClick={() => navigateWithActivityUpdate("/profile")}
          className="p-2"
        >
          <RxPerson className="text-xl text-white" />
        </button>
        <button
          onClick={() => navigateWithActivityUpdate("/search")}
          className="p-2"
        >
          <FiSearch className="text-xl text-white" />
        </button>
        <button
          onClick={toggleNotifications}
          className="relative flex items-center"
        >
          <IoNotificationsOutline className="text-xl text-white" />
          {unreadNotificationCount > 0 && (
            <span className="absolute top-0 right-0 flex items-center justify-center w-4 h-4 text-xs font-bold text-white transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full">
              {unreadNotificationCount}
            </span>
          )}
          <span className="text-sm text-white"></span>
          {isNotificationsOpen && (
            <div className="fixed inset-x-0 z-50 w-full py-2 overflow-y-auto bg-white rounded-md shadow-lg top-28">
              {sortedUnreadNotifications.length === 0 ? (
                <div className="px-4 py-2 text-sm text-gray-500">
                  No new notifications
                </div>
              ) : (
                sortedUnreadNotifications.map((notification) => (
                  <a
                    key={notification.notification_id}
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 break-words hover:bg-gray-100"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNotificationClick(
                        notification.notification_id,
                        notification.chat_id,
                      );
                    }}
                  >
                    <NotificationItem
                      key={notification.notification_id}
                      notification={notification}
                    />
                  </a>
                ))
              )}
            </div>
          )}
        </button>
        <button onClick={handleLogout} className="p-2">
          <MdLogout className="text-xl text-white" />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
