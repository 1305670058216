import React, { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../SupabaseClient";

interface Message {
  file_reference: string | undefined;
  id: string;
  chat_id: string;
  message_text: string;
  msg_timestamp: string;
  sender_id: string;
  first_name: string;
  sender_type?: "user" | "ai";
}

interface SupabaseContextType {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}

const SupabaseContext = createContext<SupabaseContextType | undefined>(
  undefined,
);

export const useSupabase = () => {
  const context = useContext(SupabaseContext);
  if (!context) {
    throw new Error("useSupabase must be used within a SupabaseProvider");
  }
  return context;
};

export const SupabaseProvider: React.FC<{ safeChatId: string }> = ({
  children,
  safeChatId,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    const messagesWatcher = supabase
      .channel("custom-all-channel")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "messages" },
        async () => {
          const { data } = await supabase.rpc("fetch_latest_messages", {
            p_chat_id: safeChatId,
          });
          if (data) {
            setMessages(data as unknown as Message[]);
          }
        },
      )
      .subscribe();

    return () => {
      messagesWatcher.unsubscribe();
    };
  }, [safeChatId]);

  return (
    <SupabaseContext.Provider value={{ messages, setMessages }}>
      {children}
    </SupabaseContext.Provider>
  );
};
