import React from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../../SupabaseClient";

interface Course {
  id: string;
  name?: string;
  description?: string;
  course_code: string;
  created_at?: string;
}

interface CourseCardProps {
  course: Course;
  darkMode: boolean;
}

const CourseCard: React.FC<CourseCardProps> = ({ course, darkMode }) => {
  const navigate = useNavigate();

  const fetchChatIdForCourse = async (courseId: string) => {
    const { data, error } = await supabase
      .from("chat")
      .select("id")
      .eq("student_course_course_id", courseId)
      .single();

    if (error) {
      throw error;
    }

    return data?.id;
  };

  const handleCourseSelect = async () => {
    try {
      const chatId = await fetchChatIdForCourse(course.id);
      if (chatId) {
        navigate(`/chat/${chatId}`);
      } else {
        console.error("No chat room found for course:", course.course_code);
      }
    } catch (error) {
      console.error("Error fetching chat ID:", error);
    }
  };
  return (
    <div
      className={`p-4 m-2 rounded-md cursor-pointer transition-all duration-300 ease-in-out ${
        darkMode
          ? "bg-gray-800 hover:bg-gray-700"
          : "bg-gray-200 hover:bg-gray-100"
      }`}
      onClick={handleCourseSelect}
    >
      <h3
        className={`mb-2 text-lg font-semibold ${
          darkMode ? "text-white" : "text-gray-900"
        }`}
      >
        {course.course_code}
      </h3>
      <p className={`text-md ${darkMode ? "text-gray-300" : "text-gray-700"}`}>
        {course.name}
      </p>
      {/* Optional content */}
    </div>
  );
};

export default CourseCard;
