import React, { ReactNode, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext"; // Assuming this is the correct path
import CircularProgress from "@mui/material/CircularProgress";

// Define the type for the component's props
interface RequireAuthProps {
  children: ReactNode;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Make sure the user context is not null before accessing its properties
    if (userContext && !userContext.isLoadingUser && !userContext.userId) {
      navigate("/welcome");
    }
  }, [userContext, navigate]);

  if (userContext?.isLoadingUser) {
    return <CircularProgress />;
  }

  return userContext?.userId ? <>{children}</> : null;
};

export default RequireAuth;
