import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  FC,
} from "react";
import { supabase } from "../SupabaseClient";
import { UserContext } from "./UserContext";
import { CircularProgress } from "@material-ui/core";
import { SupabaseChatRoomContext } from "./SupabaseChatRoomContext";
import default_profile from "../assets/default_profile.jpeg";

interface Notification {
  id: string;
  notification_id: string;
  chat_id: string;
  sender_id: string;
  msg_timestamp: string;
  message_text: string;
  sender_username: string;
  sender_avatar_url: string;
  course_code: string;
  read: boolean;
}

interface MessageNotificationContextValue {
  notifications: Notification[];
  addNotification: (notification: Notification) => void;
  markNotificationAsRead: (notificationId: string) => void;
  markMessageAsRead: (messageId: string) => Promise<void>;
}

const MessageNotificationContext = createContext<
  MessageNotificationContextValue | undefined
>(undefined);

export const useMessageNotification = (): MessageNotificationContextValue => {
  const context = useContext(MessageNotificationContext);
  if (!context) {
    throw new Error(
      "useMessageNotification must be used within a MessageNotificationProvider",
    );
  }
  return context;
};

export const MessageNotificationProvider: FC = ({ children }) => {
  const userContext = useContext(UserContext);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const audio = new Audio("/uprconnect_notification.mp3"); // Audio for notifications
  const chatroomContext = useContext(SupabaseChatRoomContext);

  if (!userContext || !chatroomContext) {
    return <CircularProgress />;
  }
  const { userId } = userContext;
  //   const { directChatRooms, courseChatRooms } = chatroomContext;

  const addNotification = (notification: Notification) => {
    // Ensure each notification has a notification_id
    const processedNotification = {
      ...notification,
      notification_id: notification.notification_id || notification.id,
      sender_username: notification.sender_username || "Unknown User",
      sender_avatar_url: notification.sender_avatar_url || default_profile,
    };

    setNotifications((prev) => {
      // Check for existing notification
      const existingIndex = prev.findIndex(
        (n) => n.notification_id === processedNotification.notification_id,
      );

      if (existingIndex !== -1) {
        // Replace existing notification
        const updatedNotifications = [...prev];
        updatedNotifications[existingIndex] = processedNotification;
        return updatedNotifications;
      }

      // Add new notification
      return [...prev, processedNotification];
    });

    // Play audio for new notifications
    if (!processedNotification.read) {
      audio.play().catch((e) => console.error("Error playing sound: ", e));
    }
  };
  const markNotificationAsRead = (notificationId: string) => {
    setNotifications((prev) =>
      prev.map((n) =>
        n.notification_id === notificationId ? { ...n, read: true } : n,
      ),
    );
  };

  async function fetchUserDetails(userId: string) {
    const { data, error } = await supabase
      .from("users")
      .select("username, avatar_url")
      .eq("id", userId)
      .single();

    if (error) {
      console.error("Error fetching user details:", error);
      return null;
    }

    return data;
  }

  const markMessageAsRead = async (messageId: string) => {
    try {
      const { error } = await supabase.from("user_message_reads").insert({
        user_id: userContext.userId,
        message_id: messageId,
        read_at: new Date().toISOString(),
      });

      if (error) {
        throw error;
      }
      // Update the notification state
      setNotifications((prev) =>
        prev.map((n) =>
          n.notification_id === messageId ? { ...n, read: true } : n,
        ),
      );
    } catch (error) {
      console.error("Error marking message as read:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      const fetchExistingNotifications = async () => {
        try {
          const { data, error } = await supabase.rpc(
            "fetch_user_notifications",
            {
              p_user_id: userId,
            },
          );
          if (error) throw error;

          // Type assertion for the fetched data
          const notificationsData = data as Notification[];

          // De-duplicate notifications
          const uniqueNotifications = Array.from(
            new Map(
              notificationsData.map((notification) => [
                notification["notification_id"],
                notification,
              ]),
            ).values(),
          ) as Notification[]; // Type assertion for the Map values

          setNotifications(uniqueNotifications);
        } catch (error) {
          console.error("Error fetching notifications:", error);
        }
      };
      fetchExistingNotifications();

      const messagesWatcher = supabase
        .channel("messages-notification-channel")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "messages" },
          async (payload) => {
            const newMessage = payload.new as Notification;
            if (newMessage.sender_id !== userId) {
              // Check if the chat room is relevant to the user
              const isUserChatRoom = chatroomContext.isChatRoomForUser(
                newMessage.chat_id,
              );
              if (isUserChatRoom) {
                // Now fetch additional user details before adding the notification
                const userDetails = await fetchUserDetails(
                  newMessage.sender_id,
                );
                if (userDetails) {
                  addNotification({
                    ...newMessage,
                    sender_username: userDetails.username,
                    sender_avatar_url: userDetails.avatar_url,
                    read: false,
                  });
                }
              }
            }
          },
        )
        .subscribe();

      return () => {
        messagesWatcher.unsubscribe();
      };
    }
  }, [userId, chatroomContext]);

  return (
    <MessageNotificationContext.Provider
      value={{
        notifications,
        addNotification,
        markNotificationAsRead,
        markMessageAsRead,
      }}
    >
      {children}
    </MessageNotificationContext.Provider>
  );
};
