import React from "react";
import CourseCard from "./CourseCard";

export interface Course {
  id: string;
  name?: string;
  description?: string;
  course_code: string;
  created_at?: string;
}

interface CourseListProps {
  courses: Course[];
  darkMode: boolean;
}

const CourseList: React.FC<CourseListProps> = ({ courses, darkMode }) => {
  return (
    <div
      className={`flex flex-col p-2 overflow-auto grow ${
        darkMode ? "bg-slate-700 text-white" : "bg-white text-gray-900"
      }`}
    >
      {courses.map((course) => (
        <CourseCard
          key={course.id || course.course_code}
          course={course}
          darkMode={darkMode}
        />
      ))}
    </div>
  );
};

export default CourseList;
