import React, { useContext, useEffect } from "react";
import Header from "./Header";
import { CircularProgress, Typography } from "@material-ui/core";
import CourseList from "../Course/CourseList";
import defaultProfile from "../../assets/default_profile.jpeg";
//import Sidebar from "../CommonComponents/Sidebar";
import MyCalendar from "../Calendar/Calendar";
import UpcomingEvents from "./UpcomingEvents";
import { SupabaseEventsProvider } from "../SupabaseEventsContext";
import { useDarkMode } from "../DarkModeContext";
import { UserContext } from "../UserContext";
import { useNavigate } from "react-router-dom";
import Navbar from "../CommonComponents/Navbar";
import MotivationalQuote from "./MotivationalQuote";

const HomePage = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate(); // Initialize navigate function
  const { darkMode, toggleDarkMode } = useDarkMode();

  useEffect(() => {
    if (!userContext?.isLoadingUser) {
      if (!userContext?.userId) {
        navigate("/welcome");
      } else if (
        userContext?.courseDetails &&
        userContext?.courseDetails.length === 0
      ) {
        navigate("/upload");
      }
    }
  }, [userContext, navigate]);

  // If the userContext is not available or if user details are still loading, show a loading indicator
  if (!userContext || userContext.isLoadingUser) {
    return <CircularProgress />;
  }

  // Destructure the necessary properties from userContext
  const { userDetails, userUniversity, courseDetails } = userContext;

  const firstName = userDetails?.first_name || "";
  const avatarUrl = userDetails?.avatar_url || defaultProfile;
  const universityColor = userUniversity?.color || "#045887";
  const buttonText = userUniversity?.universityButtonText || "Home";
  const buttonUrl =
    userUniversity?.universityButtonUrl || "https://home.uprm.edu/";
  //const textColor = darkMode ? "text-white" : "text-black";
  return (
    <div
      className={`flex flex-col min-h-screen ${
        darkMode ? "bg-slate-800" : "bg-gray-100"
      }`}
    >
      <Navbar />

      <div className="flex-grow p-6 text-black lg:pl-4 lg:pb-0 dark:text-white">
        {" "}
        {/* Adjust bottom padding for mobile */}
        <Header
          firstName={firstName}
          userProfileImage={avatarUrl}
          toggleDarkMode={toggleDarkMode}
          universityColor={universityColor}
          buttonText={buttonText}
          buttonUrl={buttonUrl}
          darkMode={darkMode}
        />
        {/* Main content area */}
        <div className="flex flex-col pt-[14px] gap-8 lg:flex-row relative">
          {/* Calendar component with flex-grow to fill available space */}
          <div className="flex-grow hidden p-2 bg-white shadow lg:block dark:bg-slate-700 rounded-xl">
            <MyCalendar />
          </div>

          {/* Fixed-width sidebar for Upcoming Events and Courses */}
          <div className="flex flex-col w-full space-y-4 lg:w-1/3">
            {/* Upcoming Events with independent scroll */}
            <div className="flex-grow p-2 overflow-y-auto bg-white shadow dark:bg-slate-700 rounded-xl">
              <Typography variant="h6" className="text-center">
                Upcoming Events
              </Typography>
              <SupabaseEventsProvider>
                <UpcomingEvents />
              </SupabaseEventsProvider>
            </div>

            {/* Courses list with independent scroll */}
            <div className="flex-grow p-2 overflow-y-auto bg-white shadow dark:bg-slate-700 rounded-xl">
              <Typography variant="h6" className="text-center">
                Your courses this semester
              </Typography>
              <CourseList courses={courseDetails || []} darkMode={darkMode} />
            </div>
          </div>
          {/* MotivationalQuote component positioned at bottom of sidebar */}
          <div className="lg:fixed lg:bottom-8 lg:right-8">
            <MotivationalQuote />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
