import React from "react";

const UserAgreement = () => {
  return (
    <div className="container px-4 py-4 mx-auto">
      <h1 className="my-4 text-3xl font-bold text-center">
        UPRConnect User Agreement
      </h1>
      <p>Last Updated: November 21, 2023</p>

      <ul className="list-disc list-inside">
        <li>
          <strong>Introduction:</strong> Welcome to UPRConnect. By using our
          services, you agree to the terms and conditions outlined in this User
          Agreement. Please read them carefully.
        </li>
        <li>
          <strong>Data Usage and Privacy:</strong>
          <ul className="ml-6 list-disc list-inside">
            <li>
              <strong>Message and Image Moderation:</strong> To maintain a safe
              and respectful environment, we use an API to moderate messages and
              images. This moderation process involves checking content for
              inappropriate or harmful material. We assure you that there is
              zero retention of your data in this process.
            </li>
            <li>
              <strong>Privacy Policy:</strong> We value your privacy and are
              committed to protecting your personal data. For more information
              on how we collect, use, and safeguard your information, please
              refer to our Privacy Policy.
            </li>
          </ul>
        </li>
        <li>
          <strong>User Conduct and Responsibilities:</strong>
          <ul className="ml-6 list-disc list-inside">
            <li>
              <strong>Prohibited Content:</strong> As a user of UPRConnect, you
              agree not to post or distribute content that is illegal, harmful,
              threatening, abusive, defamatory, or otherwise objectionable.
            </li>
            <li>
              <strong>Copyright Material:</strong> You must not distribute
              copyrighted material, such as books or course materials, without
              proper authorization. Doing so is a violation of copyright laws
              and our platform policies.
            </li>
          </ul>
        </li>
        <li>
          <strong>User Content:</strong> You retain ownership of the content you
          post on UPRConnect. However, by posting, you grant us a license to
          use, modify, publicly perform, display, reproduce, and distribute such
          content on and through our service.
        </li>
        <li>
          <strong>Termination and Account Cancellation:</strong> We may
          terminate or suspend your access to our service immediately, without
          prior notice or liability, for any reason, including but not limited
          to a breach of this User Agreement.
        </li>
        <li>
          <strong>Changes to This Agreement:</strong> We reserve the right to
          modify these terms at any time. We will provide notice of any
          significant changes to this agreement and indicate the date of the
          latest revisions.
        </li>
        <li>
          <strong>Contact Us:</strong> If you have any questions about this User
          Agreement, please contact us.
        </li>
      </ul>
    </div>
  );
};

export default UserAgreement;
