import React, { createContext, useContext, useEffect, useState } from "react";
import supabase from "../SupabaseClient";

interface Event {
  id: string;
  title: string;
  start: string;
  end: string;
  all_day: boolean;
  type: string;
  location: string;
}

interface SupabaseEventsContextType {
  events: Event[];
  setEvents: React.Dispatch<React.SetStateAction<Event[]>>;
}

const SupabaseEventsContext = createContext<
  SupabaseEventsContextType | undefined
>(undefined);

export const useSupabaseEvents = () => {
  const context = useContext(SupabaseEventsContext);
  if (!context) {
    throw new Error(
      "useSupabaseEvents must be used within a SupabaseEventsProvider",
    );
  }
  return context;
};

export const SupabaseEventsProvider: React.FC = ({ children }) => {
  const [events, setEvents] = useState<Event[]>([]);

  const fetchEvents = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    const userId = user?.id;
    const { data, error } = await supabase
      .from("events")
      .select("*")
      .eq("user_id", userId)
      .order("start", { ascending: true })
      .limit(5);

    if (error) console.error(error);
    else setEvents(data as Event[]);
  };

  useEffect(() => {
    fetchEvents();
    const eventsWatcher = supabase
      .channel("custom-all-channel")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "events" },
        async () => {
          fetchEvents();
        },
      )
      .subscribe();

    return () => {
      supabase.removeChannel(eventsWatcher);
    };
  }, []);

  return (
    <SupabaseEventsContext.Provider value={{ events, setEvents }}>
      {children}
    </SupabaseEventsContext.Provider>
  );
};
