import React from "react";
import { useNavigate } from "react-router-dom";

const ConfirmEmail = () => {
  const navigate = useNavigate();

  const handleBackToWelcome = () => {
    navigate("/welcome");
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen px-4 bg-gray-100">
      <div className="max-w-sm p-8 bg-white rounded-lg shadow-md">
        <h1 className="mb-4 text-2xl font-semibold text-gray-800">
          Confirm Your Email Address
        </h1>
        <p className="text-gray-600">
          We've sent a confirmation email to your address. Please click the link
          within to confirm your account. This can sometimes take a few minutes
          to arrive and it may land in your spam or junk folder, so be sure to
          check there.
        </p>
        <p className="mt-2 text-gray-600">
          For any issues, please contact us at admin@uprconnect.com.
        </p>
        <button
          onClick={handleBackToWelcome}
          className="w-full px-4 py-2 mt-4 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
        >
          Back to Welcome
        </button>
      </div>
    </div>
  );
};

export default ConfirmEmail;
