import React, { useContext, useEffect, useRef, useState } from "react";
import { CircularProgress, Snackbar, Alert, Avatar } from "@mui/material";
import supabase from "../../SupabaseClient";
//import Sidebar from "../CommonComponents/Sidebar";
// import defaultProfile from "../../assets/default_profile.jpeg";
import { useDarkMode } from "../DarkModeContext";
import { UserContext } from "../UserContext";
import { IoClose, IoSettingsOutline } from "react-icons/io5";
import ProfileEditor from "./ProfileEditor";
import { useNavigate, useParams } from "react-router-dom";
//import { FaLinkedin } from "react-icons/fa";
import { SocialIcon } from "react-social-icons";
import Navbar from "../CommonComponents/Navbar";

interface UserProfile {
  first_name: string;
  last_name: string;
  email: string;
  bio: string;
  username: string;
  is_online: boolean;
  university: string;
  avatar_url: string;
  allow_direct_messages: boolean;
  allow_profile_search: boolean;
  major: string;
  linked_profile: string;
  instagram_username: string;
  x_username: string;
}

const Profile: React.FC = () => {
  const [uploading, setUploading] = React.useState(false);
  const [uploadSuccess, setUploadSuccess] = React.useState(false);
  const [showProfileEditor, setProfileEditing] = useState(false);
  const { userId: urlUserId } = useParams<{ userId: string }>();
  const userContext = useContext(UserContext);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [isImagePopupOpen, setImagePopupOpen] = useState(false);

  const { darkMode } = useDarkMode();
  const modalContentRef = useRef<HTMLDivElement>(null);
  const handleCloseModal = (e: React.MouseEvent) => {
    // Close the modal if the clicked area is not the modal content
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(e.target as Node)
    ) {
      setImagePopupOpen(false);
    }
  };
  const navigate = useNavigate();
  if (!userContext) {
    console.error(
      "UserContext is null, this component must be rendered within a UserProvider",
    );
    return <CircularProgress />;
  }

  const { userId, userDetails, setUserDetails } = userContext;

  useEffect(() => {
    // Check if the URL contains a userId parameter

    if (urlUserId) {
      // If urlUserId is different from the logged-in user's id, fetch that user's profile
      if (urlUserId !== userId) {
        fetchUserProfile(urlUserId);
      } else {
        // If they're the same, use the details from the context
        setUserProfile(userDetails);
      }
    } else {
      // If there is no userId parameter in the URL, it means we're viewing our own profile
      setUserProfile(userDetails);
    }
  }, [urlUserId, userId, userDetails]);

  const fetchUserProfile = async (id: string) => {
    try {
      const { data, error } = await supabase
        .from("users")
        .select("*")
        .eq("id", id)
        .single();

      if (error) throw error;

      if (data) {
        setUserProfile(data);
      } else {
        // Handle case where no data comes back
        console.error("No profile found");
        navigate("/homepage"); // Or some error page
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      navigate("/homepage"); // Or some error page
    }
  };

  if (!userProfile) {
    return <CircularProgress />;
  }

  const displayProfile = userProfile || userDetails;

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file && userDetails) {
      setUploading(true);
      try {
        const filePath = `${userId}/${file.name}`;
        if (userDetails.avatar_url) {
          const oldFilePathArray = userDetails.avatar_url.split("/");
          const oldFilePath =
            oldFilePathArray[oldFilePathArray.length - 2] +
            "/" +
            oldFilePathArray[oldFilePathArray.length - 1];
          const { error: deleteError } = await supabase.storage
            .from("profile_pictures")
            .remove([oldFilePath]);
          if (deleteError) {
            console.error(deleteError.message);
            setUploading(false);
            return;
          }
        }
        const { error: uploadError } = await supabase.storage
          .from("profile_pictures")
          .upload(filePath, file);
        if (uploadError) {
          console.error("upload error", uploadError.message);
          return;
        }
        const avatarUrl = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profile_pictures/${filePath}`;
        const { error: updateError } = await supabase
          .from("users")
          .update({ avatar_url: avatarUrl })
          .match({ email: userDetails.email });
        if (updateError) {
          console.error(updateError.message);
        } else {
          setUploadSuccess(true);
          setTimeout(() => setUploadSuccess(false), 3000);
        }
      } finally {
        setUploading(false);
      }
    }
  };

  const handleUserProfileUpdate = (updatedProfile: UserProfile) => {
    setUserDetails(updatedProfile);
  };

  if (!userDetails || userId === null) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          backgroundColor: darkMode ? "#121212" : "#ffffff",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  // Function to close the ProfileEditor modal
  const handleCloseProfileEditor = () => {
    setProfileEditing(false);
  };

  const formatLinkedInUrl = (url: string) => {
    if (!url) return "";

    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    } else {
      return `https://${url}`;
    }
  };

  const linkedInURL = formatLinkedInUrl(displayProfile.linked_profile);
  const instagramProfileUrl = `https://www.instagram.com/${displayProfile.instagram_username}/`;
  const xProfileUrl = `https://twitter.com/${displayProfile.x_username}/`;

  // Determine if the user is viewing their own profile
  const isOwnProfile = userId === urlUserId || !urlUserId;

  return (
    <div
      className={`flex flex-col min-h-screen ${
        darkMode ? "dark" : ""
      } bg-gray-100 dark:bg-gray-800`}
    >
      <Navbar />
      {/* <Sidebar className="w-16 lg:w-16" /> */}
      <div className="flex flex-col items-center justify-center flex-grow">
        <div className="w-full max-w-3xl p-6 bg-white rounded-lg shadow-lg dark:bg-gray-700">
          <div className="relative flex-grow">
            {" "}
            {/* Make parent relative */}
            {isOwnProfile && (
              <div className="absolute top-4 right-4">
                {" "}
                {/* Position button */}
                <button
                  onClick={() => setProfileEditing(!showProfileEditor)}
                  className="text-gray-700 dark:text-white focus:outline-none"
                >
                  <IoSettingsOutline size={35} />
                </button>
              </div>
            )}
            {/* ProfileEditor Modal */}
            {showProfileEditor && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="relative p-5 bg-white rounded-lg shadow-lg dark:bg-gray-700">
                  {/* Close button */}
                  <button
                    onClick={handleCloseProfileEditor}
                    className="absolute top-0 right-0 text-gray-600 dark:text-gray-200 hover:text-gray-800 dark:hover:text-white"
                  >
                    <IoClose size={25} />
                  </button>
                  <ProfileEditor
                    userId={userId}
                    userProfile={userDetails}
                    onSave={handleCloseProfileEditor}
                    onUpdateUserProfile={handleUserProfileUpdate}
                    darkMode={darkMode}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col items-center lg:flex-row lg:items-start lg:space-x-8">
              <div className="flex flex-col items-center">
                <Avatar
                  alt={`${displayProfile.first_name} ${displayProfile.last_name}`}
                  src={displayProfile.avatar_url || undefined}
                  style={{ width: "120px", height: "120px" }}
                  className="border-white rounded-full dark:border-gray-700"
                  onClick={() => setImagePopupOpen(true)}
                />

                {/* Image Pop-up Modal */}
                {isImagePopupOpen && (
                  <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                    onClick={handleCloseModal}
                  >
                    <div
                      className="flex items-center justify-center p-4"
                      ref={modalContentRef}
                    >
                      <img
                        src={displayProfile.avatar_url || undefined}
                        alt="Profile"
                        className="rounded-full object-cover h-80 w-80 "
                      />
                    </div>
                  </div>
                )}
                {isOwnProfile && (
                  <label
                    htmlFor="file-input"
                    className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-600"
                  >
                    Change Picture
                  </label>
                )}
                <input
                  id="file-input"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </div>
              <div className="mt-8 place-self-center lg:mt-0">
                <h1 className="text-4xl font-bold text-center lg:text-left dark:text-white">
                  {displayProfile.first_name} {displayProfile.last_name}
                </h1>
                <div className="mt-4">
                  <p className="text-lg text-center lg:text-left dark:text-gray-300">
                    <span className="font-medium">
                      @{displayProfile.username}
                    </span>
                  </p>
                  <div className="flex flex-col items-center mt-2 lg:items-start">
                    <p className="flex items-center text-lg dark:text-gray-300">
                      Campus: {displayProfile.university}
                    </p>
                    <p className="flex items-center text-lg dark:text-gray-300">
                      Major: {displayProfile.major}
                    </p>
                  </div>
                  <p className="mt-4 text-lg text-justify dark:text-gray-300">
                    <span className="font-semibold">Bio:</span>{" "}
                    {displayProfile.bio || "No bio available"}
                  </p>
                </div>
                <div className="flex justify-center mt-4 space-x-4 lg:justify-start">
                  {/* LinkedIn Profile */}
                  {displayProfile.linked_profile && (
                    <SocialIcon
                      url={linkedInURL}
                      network="linkedin"
                      style={{ height: 50, width: 50 }}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )}
                  {/* Instagram Profile */}
                  {displayProfile.instagram_username && (
                    <SocialIcon
                      url={instagramProfileUrl}
                      network="instagram"
                      style={{ height: 50, width: 50 }}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )}
                  {/* X Profile */}
                  {displayProfile.x_username && (
                    <SocialIcon
                      url={xProfileUrl}
                      network="x"
                      style={{ height: 50, width: 50 }}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {uploading && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <CircularProgress />
            </div>
          )}
          {uploadSuccess && (
            <Snackbar
              open={uploadSuccess}
              autoHideDuration={6000}
              onClose={() => setUploadSuccess(false)}
            >
              <Alert
                onClose={() => setUploadSuccess(false)}
                severity="success"
                className="dark:bg-green-700"
              >
                Upload successful!
              </Alert>
            </Snackbar>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
