import React, { useEffect, useRef, useState } from "react";
import { supabase } from "../../SupabaseClient";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoClose } from "react-icons/io5";

interface ProfileEditorProps {
  userId: string;
  userProfile: UserProfile;
  onSave: () => void;
  onUpdateUserProfile: (updatedProfile: UserProfile) => void;
  darkMode: boolean;
}

interface UserProfile {
  first_name: string;
  last_name: string;
  email: string;
  bio: string;
  username: string;
  is_online: boolean;
  university: string;
  avatar_url: string;
  allow_direct_messages: boolean;
  allow_profile_search: boolean;
  major: string;
  linked_profile: string;
  instagram_username: string;
  x_username: string;
}

const ProfileEditor: React.FC<ProfileEditorProps> = ({
  userId,
  userProfile,
  onSave,
  onUpdateUserProfile,
  darkMode,
}) => {
  const [firstName, setFirstName] = useState(userProfile.first_name);
  const [lastName, setLastName] = useState(userProfile.last_name);
  const [bio, setBio] = useState(userProfile.bio);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [major, setMajor] = useState(userProfile.major);
  const [instagramUsername, setInstagramUsername] = useState(
    userProfile.instagram_username || "",
  );
  const [xUsername, setXUsername] = useState(userProfile.x_username || "");
  const [linkedProfile, setLinkedProfile] = useState(
    userProfile.linked_profile || "",
  );
  const [allowDirectMessages, setAllowDirectMessages] = useState(
    userProfile.allow_direct_messages,
  );
  const [allowProfileSearch, setAllowProfileSearch] = useState(
    userProfile.allow_profile_search,
  );
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const modalRef = useRef<HTMLDivElement>(null);
  const handleSave = async () => {
    setLoading(true);
    const updatedProfile = {
      ...userProfile,
      first_name: firstName,
      last_name: lastName,
      bio: bio,
      allow_direct_messages: allowDirectMessages,
      allow_profile_search: allowProfileSearch,
      major: major,
      linked_profile: linkedProfile,
      instagram_username: instagramUsername,
      x_username: xUsername,
    };

    const { error } = await supabase
      .from("users")
      .update({
        first_name: firstName,
        last_name: lastName,
        bio: bio,
        allow_direct_messages: allowDirectMessages,
        allow_profile_search: allowProfileSearch,
        major: major,
        linked_profile: linkedProfile,
        instagram_username: instagramUsername,
        x_username: xUsername,
      })
      .match({ email: userProfile.email });
    setLoading(false);
    if (error) {
      setSnackbarMessage("Error saving profile: " + error.message);
    } else {
      onUpdateUserProfile(updatedProfile);
      setSnackbarMessage("Profile updated successfully");
    }
    setSnackbarOpen(true);
    setTimeout(() => {
      onSave();
    }, 2000);
  };

  const handleDirectMessagesChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newAllowDirectMessages = event.target.checked;
    setAllowDirectMessages(newAllowDirectMessages);
    setLoading(true);

    const { error } = await supabase
      .from("users")
      .update({ allow_direct_messages: newAllowDirectMessages })
      .match({ id: userId });

    setLoading(false);
    if (error) {
      setSnackbarMessage("Error updating settings: " + error.message);
    } else {
      setSnackbarMessage("Settings updated successfully");
    }
    setSnackbarOpen(true);
  };

  const handleClose = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onSave(); // Calling onSave to close the modal
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClose);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClose);
    };
  }, []);

  const handleProfileSearchChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newAllowUserSearch = event.target.checked;
    setAllowProfileSearch(newAllowUserSearch);
    setLoading(true);
    const { error } = await supabase
      .from("users")
      .update({ allow_profile_search: newAllowUserSearch })
      .match({ id: userId });

    setLoading(false);
    if (error) {
      setSnackbarMessage("Error updating settings: " + error.message);
    } else {
      setSnackbarMessage("Settings updated successfully");
    }
    setSnackbarOpen(true);
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setSnackbarMessage("New passwords do not match!");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    const { error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    setLoading(false);

    if (error) {
      setSnackbarMessage("Error updating password: " + error.message);
      setSnackbarOpen(true);
      console.error(error.message);
    } else {
      setSnackbarMessage("Password updated successfully!");
      setSnackbarOpen(true);
      // Optionally, clear the password fields after successful update
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  // Fetch initial state from the database
  useEffect(() => {
    const fetchUserSettings = async () => {
      const { data, error } = await supabase
        .from("users")
        .select("allow_direct_messages")
        .eq("id", userId)
        .single();

      if (error) {
        console.error("Error fetching user settings:", error);
      } else {
        setAllowDirectMessages(data.allow_direct_messages);
      }
    };

    fetchUserSettings();
  }, [userId]);

  const inputFieldStyle = `w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
    darkMode
      ? "bg-gray-700 text-white border-gray-600"
      : "bg-white text-gray-800 border-gray-300"
  }`;

  // Inside Profile Editor Component
  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center p-4 ${
        darkMode ? "bg-black bg-opacity-50" : "bg-gray-200 bg-opacity-50"
      }`}
    >
      <div
        className={`relative w-full max-w-md p-6 mx-auto ${
          darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
        } rounded-lg shadow-lg`}
        ref={modalRef}
      >
        <button
          onClick={onSave}
          className={`absolute top-0 right-0 p-2 ${
            darkMode
              ? "text-gray-200 hover:text-white"
              : "text-gray-600 hover:text-gray-800"
          }`}
        >
          <IoClose size={25} />
        </button>
        <div className="grid grid-cols-1 gap-6">
          {/* First Name Field */}
          <div>
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="firstName"
            >
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              className={inputFieldStyle}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          {/* Last Name Field */}
          <div>
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="lastName"
            >
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              className={inputFieldStyle}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          {/* Bio Field */}
          <div className="col-span-2">
            <label className="block mb-1 text-sm font-medium" htmlFor="bio">
              Bio
            </label>
            <textarea
              id="bio"
              rows={3}
              className={inputFieldStyle}
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            ></textarea>
          </div>
          {/* Major Field */}
          <div>
            <label className="block mb-1 text-sm font-medium" htmlFor="major">
              Major
            </label>
            <input
              type="text"
              id="major"
              className={inputFieldStyle}
              value={major}
              onChange={(e) => setMajor(e.target.value)}
            />
          </div>
          {/* LinkedIn Profile Field */}
          <div>
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="linkedProfile"
            >
              LinkedIn Profile (URL)
            </label>
            <input
              type="text"
              id="linkedProfile"
              className={inputFieldStyle}
              placeholder="https://www.linkedin.com/in/yourprofile"
              value={linkedProfile}
              onChange={(e) => setLinkedProfile(e.target.value)}
            />
          </div>
          {/* Instagram Username Field */}
          <div>
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="instagramUsername"
            >
              Instagram Username
            </label>
            <input
              type="text"
              id="instagramUsername"
              className={inputFieldStyle}
              placeholder="Instagram Username"
              value={instagramUsername}
              onChange={(e) => setInstagramUsername(e.target.value)}
            />
          </div>
          {/* X Username Field */}
          <div>
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="x_username"
            >
              X Username
            </label>
            <input
              type="text"
              id="x_username"
              className={inputFieldStyle}
              placeholder="X Username"
              value={xUsername}
              onChange={(e) => setXUsername(e.target.value)}
            />
          </div>
          {/* Password Fields */}
          <div>
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="currentPassword"
            >
              Current Password
            </label>
            <input
              type="password"
              id="currentPassword"
              className={inputFieldStyle}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="newPassword"
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              className={inputFieldStyle}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="col-span-2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="confirmPassword"
            >
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              className={inputFieldStyle}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          {currentPassword && newPassword && (
            <button
              onClick={handleChangePassword}
              className="col-span-2 px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading ? (
                <AiOutlineLoading3Quarters className="animate-spin" />
              ) : (
                "Change Password"
              )}
            </button>
          )}
          {/* Allow Direct Messages and Profile Search Switch */}
          <div className="flex items-center justify-between col-span-2 mb-4">
            <span>Allow Direct Messages</span>
            <input
              type="checkbox"
              checked={allowDirectMessages}
              onChange={handleDirectMessagesChange}
              className="w-6 h-6 text-blue-600 rounded-md focus:ring-blue-500"
            />
            <span>Allow Profile Search</span>
            <input
              type="checkbox"
              checked={allowProfileSearch}
              onChange={handleProfileSearchChange}
              className="w-6 h-6 text-blue-600 rounded-md focus:ring-blue-500"
            />
          </div>
          {/* Save Button */}
          <div className="flex justify-center col-span-2">
            <button
              onClick={handleSave}
              className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading ? (
                <AiOutlineLoading3Quarters className="animate-spin" />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
      {snackbarOpen && (
        <div className="fixed px-4 py-2 text-white bg-blue-500 rounded-md shadow-md bottom-5 right-5">
          {snackbarMessage}
          <button
            onClick={() => setSnackbarOpen(false)}
            className="ml-4 text-xl"
          >
            <IoClose />
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileEditor;
