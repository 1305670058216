import React, { useState, useEffect } from "react";
import { EventInput } from "@fullcalendar/core";
import { useDarkMode } from "../DarkModeContext";

interface EventData {
  title: string;
  type: string;
  location: string;
  start: string;
  end: string;
  allDay: boolean; // Add this line
}
interface EventModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (event: EventData) => void;
  initialStart: string;
  initialEnd: string;
  selectedEvent?: EventInput | null | undefined;
  onDelete: (eventId: string) => void;
}
function EventModal({
  isOpen,
  onClose,
  onSubmit,
  initialStart,
  initialEnd,
  selectedEvent,
  onDelete,
}: EventModalProps) {
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [location, setLocation] = useState("");
  const [start, setStart] = useState(initialStart); // Use initialStart here
  const [end, setEnd] = useState(initialEnd); // Use initialEnd here
  const [allDay, setAllDay] = useState(false); // New state for All Day
  const [errorMessage, setErrorMessage] = useState("");

  const handleEventSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Convert local time to UTC before submitting
    const startUTC = new Date(start).toISOString();
    const endUTC = new Date(end).toISOString();

    if (startUTC >= endUTC) {
      setErrorMessage("Start date must be before end date");
      return;
    }

    onSubmit({ title, type, location, start: startUTC, end: endUTC, allDay });
    setTitle("");
    setType("");
    setLocation("");
    setStart("");
    setEnd("");
    onClose();
  };

  useEffect(() => {
    function formatDate(date: Date) {
      const YYYY = date.getFullYear();
      const MM = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
      const DD = String(date.getDate()).padStart(2, "0");
      const hh = String(date.getHours()).padStart(2, "0");
      const mm = String(date.getMinutes()).padStart(2, "0");
      return `${YYYY}-${MM}-${DD}T${hh}:${mm}`;
    }
    if (allDay) {
      const dateFormatted = new Date(initialStart)
        .toISOString()
        .substring(0, 10);
      setStart(`${dateFormatted}T00:00`);
      setEnd(`${dateFormatted}T23:59`);
    } else {
      const formattedInitialStart = formatDate(new Date(initialStart));
      const formattedInitialEnd = formatDate(new Date(initialEnd));
      setStart(formattedInitialStart);
      setEnd(formattedInitialEnd);
    }
  }, [initialStart, initialEnd, allDay]);

  function formatDateTime(dateTime: string | Date, allDay: boolean) {
    if (allDay) {
      const dateObject = new Date(dateTime);
      // Define options for toLocaleDateString
      const options: Intl.DateTimeFormatOptions = {
        weekday: "long", // long, short, narrow
        year: "numeric", // numeric, 2-digit
        month: "long", // numeric, 2-digit, long, short, narrow
        day: "numeric", // numeric, 2-digit
        timeZone: "UTC", // Keep the time zone as UTC
      };

      // Return the formatted date string
      return dateObject.toLocaleDateString("en-US", options);
    }
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: "short",
    };
    const dateObject =
      typeof dateTime === "number"
        ? new Date(dateTime)
        : new Date(dateTime.toString());
    return new Intl.DateTimeFormat("en-US", options).format(dateObject);
  }

  function capitalizeFirstLetter(letter: string) {
    return letter.charAt(0).toUpperCase() + letter.slice(1).toLowerCase();
  }

  function getEventDuration(start: string, end: string, allDay: boolean) {
    if (allDay) {
      return "All day";
    }
    const startDate = new Date(start);
    const endDate = new Date(end);
    const durationMillis = endDate.getTime() - startDate.getTime();
    const hours = Math.floor(durationMillis / (1000 * 60 * 60));
    const minutes = Math.floor(
      (durationMillis % (1000 * 60 * 60)) / (1000 * 60),
    );
    return `${hours} hours ${minutes} minutes`;
  }

  if (!isOpen) {
    return null;
  }

  const { darkMode } = useDarkMode();

  // Conditionally show feedback message
  const requiredFields = ["title", "start", "end", "type"];
  const missingFields = requiredFields.filter((field) => !eval(field));

  return (
    <>
      <div
        className="fixed inset-0 z-50 bg-black bg-opacity-50"
        onClick={onClose}
      ></div>
      <div
        className={` fixed z-50 p-6 transform -translate-x-1/2 -translate-y-1/2 rounded-lg top-1/2 left-1/2 ${
          darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"
        }`}
      >
        {selectedEvent ? (
          <div className="modal-content">
            <h2 className="mb-4 text-xl font-semibold modal-header">
              {selectedEvent.title}
            </h2>
            <div className="modal-body">
              {selectedEvent.extendedProps?.type && (
                <p className="mb-2">
                  Type:{" "}
                  <span className="font-medium">
                    {capitalizeFirstLetter(selectedEvent.extendedProps.type)}
                  </span>
                </p>
              )}
              {selectedEvent.extendedProps?.location && (
                <p className="mb-2">
                  Location:{" "}
                  <span className="font-medium">
                    {selectedEvent.extendedProps.location}
                  </span>
                </p>
              )}
              {selectedEvent?.start && (
                <p className="mb-2">
                  Start:{" "}
                  <span className="font-medium">
                    {formatDateTime(
                      selectedEvent.start as string,
                      selectedEvent.allDay as boolean,
                    )}
                  </span>
                </p>
              )}
              {selectedEvent?.end && (
                <p className="mb-2">
                  End:{" "}
                  <span className="font-medium">
                    {formatDateTime(
                      selectedEvent.end as string,
                      selectedEvent.allDay as boolean,
                    )}
                  </span>
                </p>
              )}
              {!selectedEvent?.allDay && (
                <p className="mb-2">
                  Duration:{" "}
                  <span className="font-medium">
                    {getEventDuration(
                      selectedEvent.start as string,
                      selectedEvent.end as string,
                      selectedEvent.allDay as boolean,
                    )}
                  </span>
                </p>
              )}
              <p className="mb-4">
                All Day:{" "}
                <span className="font-medium">
                  {selectedEvent.allDay ? "Yes" : "No"}
                </span>
              </p>
            </div>
            {/* Optionally add a delete button */}
            <button
              onClick={() => {
                if (selectedEvent && selectedEvent.id) {
                  onDelete(selectedEvent.id);
                }
              }}
              className="block w-full px-4 py-2 mt-4 text-white transition duration-300 bg-red-500 rounded-full hover:bg-red-600 button button-delete"
            >
              Delete
            </button>

            <button
              onClick={onClose}
              className="block w-full px-4 py-2 mt-4 text-white transition duration-300 bg-blue-500 rounded-full hover:bg-blue-600 button button-close"
            >
              Close
            </button>
          </div>
        ) : (
          <form
            className="space-y-4 min-w-[500px]"
            onSubmit={handleEventSubmit}
          >
            {" "}
            <div>
              <label
                htmlFor="title"
                className={`block text-sm font-medium ${
                  darkMode ? "text-white" : "text-gray-700"
                }`}
              >
                Title
              </label>
              <input
                type="text"
                name="title"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className={`block w-full p-2 mt-1 border rounded-lg ${
                  darkMode
                    ? "bg-gray-700 border-gray-600 text-white"
                    : "bg-white border-gray-300 text-gray-900"
                }`}
              />
            </div>
            {/* Start Date Field */}
            <div>
              <label
                htmlFor="start"
                className={`block text-sm font-medium ${
                  darkMode ? "text-white" : "text-gray-700"
                }`}
              >
                Start Date
              </label>
              <input
                type="datetime-local"
                name="start"
                id="start"
                value={start}
                onChange={(e) => setStart(e.target.value)}
                className={`block w-full p-2 mt-1 border rounded-lg ${
                  darkMode
                    ? "bg-gray-700 border-gray-600 text-white placeholder-gray-500"
                    : "bg-white border-gray-300 text-gray-900 placeholder-gray-700"
                }`}
                disabled={allDay}
              />
            </div>
            {/* End Date Field */}
            <div>
              <label
                htmlFor="end"
                className={`block text-sm font-medium ${
                  darkMode ? "text-white" : "text-gray-700"
                }`}
              >
                End Date
              </label>
              <input
                type="datetime-local"
                name="end"
                id="end"
                value={end}
                onChange={(e) => setEnd(e.target.value)}
                className={`block w-full p-2 mt-1 border rounded-lg ${
                  darkMode
                    ? "bg-gray-700 border-gray-600 text-white placeholder-gray-500"
                    : "bg-white border-gray-300 text-gray-900 placeholder-gray-700"
                }`}
                disabled={allDay}
              />
            </div>
            <div>
              <label
                htmlFor="type"
                className={`block text-sm font-medium ${
                  darkMode ? "text-white" : "text-gray-700"
                }`}
              >
                Event Type
              </label>
              <select
                name="type"
                id="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                className={`block w-full p-2 mt-1 border rounded-lg ${
                  darkMode
                    ? "bg-gray-700 border-gray-600 text-white placeholder-gray-500"
                    : "bg-white border-gray-300 text-gray-900 placeholder-gray-700"
                }`}
              >
                <option value="" disabled>
                  Select event type
                </option>
                <option value="exam">Exam</option>
                <option value="quiz">Quiz</option>
                <option value="assignment">Assignment</option>
                <option value="other">Other</option>
              </select>
            </div>
            {/* All Day Checkbox */}
            <div>
              <label
                htmlFor="allDay"
                className={`block text-sm font-medium ${
                  darkMode ? "text-white" : "text-gray-700"
                }`}
              >
                All Day
              </label>
              <input
                type="checkbox"
                name="allDay"
                id="allDay"
                checked={allDay}
                onChange={(e) => setAllDay(e.target.checked)}
                className="block mt-1"
              />
            </div>
            {/* Location Field */}
            <div>
              <label
                htmlFor="location"
                className={`block text-sm font-medium ${
                  darkMode ? "text-white" : "text-gray-700"
                }`}
              >
                Location
              </label>
              <input
                type="text"
                name="location"
                id="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className={`block w-full p-2 mt-1 border rounded-lg ${
                  darkMode
                    ? "bg-gray-700 border-gray-600 text-white placeholder-gray-500"
                    : "bg-white border-gray-300 text-gray-900 placeholder-gray-700"
                }`}
              />
            </div>
            {/* Submit Button */}
            <button
              onClick={handleEventSubmit}
              type="submit"
              disabled={!title || !start || !end || !type}
              className={`block w-full px-4 py-2 rounded-full text-white ${
                darkMode
                  ? "bg-blue-600 hover:bg-blue-700"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Submit
            </button>
            {
              // Conditionally show feedback message
              missingFields.length > 0 && (
                <p className="mt-6 text-sm text-red-500">
                  Please fill in all required fields:{" "}
                  {missingFields
                    .map(
                      (field) => field.charAt(0).toUpperCase() + field.slice(1),
                    )
                    .join(", ")}
                  .
                </p>
              )
            }
          </form>
        )}
        {errorMessage && <div className="text-red-500">{errorMessage}</div>}
      </div>
    </>
  );
}

export default EventModal;
