import { Avatar } from "@mui/material";
import React from "react";
import { FaSun, FaMoon } from "react-icons/fa";
import BottomNavButtons from "../CommonComponents/BottomNavButtons";

// In Header.tsx

interface HeaderProps {
  firstName: string;
  userProfileImage: string;
  toggleDarkMode: () => void;
  darkMode: boolean;
  universityColor: string;
  buttonText: string;
  buttonUrl: string;
}

const Header: React.FC<HeaderProps> = ({
  firstName,
  userProfileImage,
  toggleDarkMode,
  darkMode,
  universityColor,
  buttonText,
  buttonUrl,
}) => {
  return (
    <header className="flex items-center justify-between p-3">
      <div className="flex items-center ">
        <div
          className={`text-3xl font-bold ${
            darkMode ? "text-white" : "text-black"
          } flex-grow sm:text-left text-center`}
        >
          Welcome to UPRConnect, {firstName}!
        </div>
      </div>

      {/* Navigation buttons centered for mobile and hidden for larger screens */}
      <div className="hidden sm:block">
        <BottomNavButtons
          universityColor={universityColor}
          buttonUrl={buttonUrl}
          buttonText={buttonText}
          darkMode={darkMode}
        />
      </div>

      {/* Right side of the header with dark mode toggle and avatar */}
      <div className="relative">
        <Avatar
          src={userProfileImage}
          alt="User Profile"
          style={{ width: "90px", height: "90px" }}
          className="absolute top-0 right-0"
        />
        <button
          onClick={toggleDarkMode}
          className={`absolute p-2 rounded-full ${
            darkMode ? "bg-gray-600" : "bg-gray-200"
          } -top-2 -right-2`}
          aria-label="Toggle Dark Mode"
        >
          {darkMode ? (
            <FaSun className="text-yellow-400" />
          ) : (
            <FaMoon className="text-gray-600" />
          )}
        </button>
      </div>
    </header>
  );
};

export default Header;
