import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import supabase from "../../SupabaseClient";
import Header from "./Header";

const SignUpPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedUniversity, setSelectedUniversity] = React.useState("");
  const [warning, setWarning] = useState("");
  const [avatar, setAvatar] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setAvatar(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSignUpSubmit = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const uprDomainRegex = /.+@upr\.edu$/;
    const requiredFields = [
      "firstName",
      "lastName",
      "password",
      "confirmPassword",
      "selectedUniversity",
    ];
    const fieldDisplayNames: { [key: string]: string } = {
      firstName: "First Name",
      lastName: "Last Name",
      password: "Password",
      confirmPassword: "Confirm Password",
      selectedUniversity: "Select University",
    };

    const missingFields = requiredFields.filter((field) => !eval(field));

    if (missingFields.length > 0) {
      const missingFieldDisplayNames = missingFields.map(
        (field) => fieldDisplayNames[field],
      );
      setWarning(
        `Please fill in all required fields: ${missingFieldDisplayNames.join(
          ", ",
        )}`,
      );
      return;
    }
    if (!emailRegex.test(email)) {
      setWarning("Please enter a valid email address.");
      return;
    }
    if (!uprDomainRegex.test(email)) {
      setWarning("Please use a .upr.edu email address.");
      return;
    }
    if (password !== confirmPassword) {
      setWarning("Passwords do not match.");
      return;
    }

    const signUpResult = await supabase.auth.signUp({
      email,
      password,
    });

    const user = signUpResult.data?.user;
    if (!user) {
      setWarning(
        signUpResult.error?.message || "Sign-up failed for an unknown reason.",
      );
      return;
    }

    // Handle profile picture upload
    let avatarUrl = "";
    if (avatar) {
      const filePath = `${user.id}/${avatar.name}`;
      const { error: uploadError } = await supabase.storage
        .from("profile_pictures")
        .upload(filePath, avatar);

      if (uploadError) {
        setWarning(uploadError.message);
        return;
      }

      avatarUrl = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profile_pictures/${filePath}`;
    }

    // Insert additional user data into your user table
    const { error: insertError } = await supabase.from("users").insert([
      {
        id: user.id,
        email,
        first_name: firstName,
        last_name: lastName,
        university: selectedUniversity,
        avatar_url: avatarUrl,
      },
    ]);

    if (insertError) {
      console.error("Error inserting user:", insertError.message);
      setWarning(insertError.message);
      return;
    }
    if (!insertError && user) {
      setOnlineStatus(user.id, true);
    }
    // Set success message on successful sign-up and insert
    setSuccessMessage(
      "Sign-up successful! Please check your email to verify your account.",
    );
    resetForms();
    // Delay navigation to the "/welcome" page by 2 seconds
    navigate("/upload");
  };

  const redirectToWelcome = () => {
    navigate("/welcome");
  };

  const resetForms = () => {
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setConfirmPassword("");
    setWarning("");
  };

  const setOnlineStatus = async (userId: string, status: boolean) => {
    try {
      const { error } = await supabase.rpc("set_user_online_status", {
        user_id: userId,
        online_status: status,
      });

      if (error) {
        console.error("Error updating online status:", error);
      }
    } catch (err) {
      console.error("Error calling RPC:", err);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-white">
      <Header />
      <div className="flex items-center justify-center flex-grow">
        <div className="w-full max-w-md p-4 bg-white rounded-lg shadow-md">
          <h2 className="mb-6 text-2xl font-bold text-center">
            Connect, Collaborate, and Create
          </h2>
          <input
            className="block w-full px-4 py-2 mb-2 text-gray-700 bg-gray-200 border rounded-md focus:border-blue-500 focus:bg-white focus:outline-none"
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoComplete="given-name"
          />
          <input
            className="block w-full px-4 py-2 mb-2 text-gray-700 bg-gray-200 border rounded-md focus:border-blue-500 focus:bg-white focus:outline-none"
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            autoComplete="family-name"
          />
          <input
            className="block w-full px-4 py-2 mb-2 text-gray-700 bg-gray-200 border rounded-md focus:border-blue-500 focus:bg-white focus:outline-none"
            type="email"
            placeholder="Email (upr.edu only)"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="block w-full px-4 py-2 mb-2 text-gray-700 bg-gray-200 border rounded-md focus:border-blue-500 focus:bg-white focus:outline-none"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            className="block w-full px-4 py-2 mb-2 text-gray-700 bg-gray-200 border rounded-md focus:border-blue-500 focus:bg-white focus:outline-none"
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {/* Dropdown for selecting university */}
          <select
            className="block w-full px-4 py-2 mb-4 text-gray-700 bg-gray-200 border rounded-md focus:border-blue-500 focus:bg-white focus:outline-none"
            value={selectedUniversity}
            onChange={(e) => setSelectedUniversity(e.target.value)}
          >
            <option value="" disabled>
              Select your university
            </option>
            {/* <option value="UPRAG">
              University of Puerto Rico at Aguadilla
            </option>
            <option value="UPRA">University of Puerto Rico at Arecibo</option>
            <option value="UPRB">University of Puerto Rico at Bayamón</option>
            <option value="UPRCA">University of Puerto Rico at Carolina</option>
            <option value="UPRC">University of Puerto Rico at Cayey</option>
            <option value="UPRH">University of Puerto Rico at Humacao</option> */}
            <option value="UPRM">University of Puerto Rico at Mayagüez</option>
            {/* <option value="UPRP">University of Puerto Rico at Ponce</option> */}
            {/* <option value="UPRRP">
              University of Puerto Rico at Río Piedras
            </option> */}
            {/* <option value="UPRU">University of Puerto Rico at Utuado</option> */}
          </select>

          <div className="mb-4">
            <input
              id="avatar-upload"
              type="file"
              className="hidden"
              onChange={handleFileSelect}
            />
            <label
              htmlFor="avatar-upload"
              className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md cursor-pointer hover:bg-blue-700 focus:outline-none"
            >
              Upload Profile Picture
            </label>
          </div>
          {/* Display selected avatar */}

          {previewUrl ? (
            <Avatar
              src={previewUrl}
              alt="Profile Preview"
              // Increase the size by using larger numbers
              className="w-32 h-32 mx-auto mb-4" // This will make it 8rem x 8rem or 128px x 128px
              style={{ width: "128px", height: "128px" }} // Or you can use inline styles for exact sizes
            />
          ) : (
            <Avatar
              className="w-32 h-32 mx-auto mb-4" // Adjust these classes to increase the default avatar size as well
              style={{ width: "128px", height: "128px" }} // Inline styles for exact sizes
            />
          )}
          {warning && (
            <div className="mb-4 text-sm text-red-600">{warning}</div>
          )}
          {successMessage && (
            <div className="mb-4 text-sm text-green-600">{successMessage}</div>
          )}
          <button
            className="w-full py-3 mb-2 text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none"
            onClick={handleSignUpSubmit}
          >
            Agree & Join
          </button>
          <p className="mt-4 text-xs text-center">
            By clicking Agree & Join, you agree to the{" "}
            <a
              href="/user-agreement"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              UPRConnect User Agreement
            </a>
            .
          </p>

          <p className="mt-4 text-center">
            Already in UPRConnect?{" "}
            <button
              onClick={redirectToWelcome}
              className="text-blue-600 hover:underline"
            >
              Sign in
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
