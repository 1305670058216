import React, { useContext, useEffect, useRef, useState } from "react";
import { supabase } from "../../SupabaseClient";
import { v4 as uuidv4 } from "uuid";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { UserContext } from "../UserContext";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import { OpenAI } from "openai";

interface ChatInputProps {
  chatId: string;
  onLoadingChange?: (isLoading: boolean) => void;
  onUploadFeedback?: (feedback: string) => void;
  isAIChat?: boolean;
}
interface Concept {
  id: string;
  name: string;
  value: number;
  // Include any other properties returned in the concept object
}
// Function for calling the NSFW check API
const checkForNSFWContent = async (imageUrl: string): Promise<boolean> => {
  const PAT = process.env.REACT_APP_CLARIFAI_API_KEY;
  const USER_ID = "clarifai";
  const APP_ID = "main";
  const MODEL_ID = "moderation-recognition";
  const MODEL_VERSION_ID = "aa8be956dbaa4b7a858826a84253cab9";
  const requestBody = JSON.stringify({
    user_app_id: {
      user_id: USER_ID,
      app_id: APP_ID,
    },
    inputs: [
      {
        data: {
          image: {
            url: imageUrl,
          },
        },
      },
    ],
  });

  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Key ${PAT}`,
    },
    body: requestBody,
  };

  try {
    const response = await fetch(
      `https://api.clarifai.com/v2/models/${MODEL_ID}/versions/${MODEL_VERSION_ID}/outputs`,
      requestOptions,
    );
    const result = await response.json();
    // Find the highest value among the concerning concepts.
    const highestConcernValue = result.outputs[0].data.concepts
      .filter((concept: Concept) =>
        ["explicit", "drug", "gore", "suggestive"].includes(concept.name),
      )
      .reduce(
        (max: number, concept: Concept) =>
          concept.value > max ? concept.value : max,
        0,
      );
    // Consider the image NSFW if the highest concern value is the largest number in the scale up to 1.0
    // and it is not related to 'safe'.
    return highestConcernValue > 0.3;
  } catch (error) {
    console.error("Error checking for NSFW content:", error);
    return false; // If there's an error, default to false or handle as needed
  }
};

const sanitizeFileName = (fileName: string) => {
  // Remove accents/diacritics
  const normalized = fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  // Replace spaces and unsupported characters with an underscore
  const sanitized = normalized.replace(/[^a-zA-Z0-9._-]/g, "_");
  return sanitized;
};

const ChatInput: React.FC<ChatInputProps> = ({
  chatId,
  onLoadingChange,
  onUploadFeedback, // Don't forget to destructure this prop from the props argument
  isAIChat,
}) => {
  const [message, setMessage] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [, setUploading] = useState(false);
  const [confirmNoCopyright, setConfirmNoCopyright] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const AWS_LAMBDA_ENDPOINT =
    "https://556g7y6vdinn35gnd46f2ozefe0rcmxv.lambda-url.us-east-1.on.aws/";

  const userContext = useContext(UserContext);

  if (!userContext) {
    console.error(
      "UserContext is null, this component must be rendered within a UserProvider",
    );
    return <CircularProgress />;
  }

  const { userId, updateLastActivity } = userContext;
  const isImageFile = (fileName: string) => /\.(jpe?g|png)$/i.test(fileName);
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      setFile(file);
      setMessage(file.name); // Set the message to the file name
      onUploadFeedback?.("");
      updateLoadingState(true);

      // Reset the confirmation state when a new file is selected
      setConfirmNoCopyright(false);

      // If the file is not an image, require confirmation before processing
      if (!isImageFile(file.name)) {
        // Prompt the user for confirmation
        const confirmNoCopyright = window.confirm(
          "Please confirm that the file does not contain copyrighted material.",
        );

        // If the user does not confirm, clear the file selection and do not process further
        if (!confirmNoCopyright) {
          setFile(null);
          setMessage("");
          return;
        }
      }

      // Check if the file is an image
      if (isImageFile(file.name)) {
        try {
          // Upload the image to a temporary path
          const uniqueId = uuidv4();
          const sanitizedFileName =
            uniqueId + "_" + sanitizeFileName(file.name);
          const filePath = `temporary/${userId}/${sanitizedFileName}`;
          const { error: uploadError } = await supabase.storage
            .from("chat_files")
            .upload(filePath, file);

          if (uploadError) {
            throw new Error(uploadError.message);
          }

          // Generate a public URL for the uploaded file
          const { data: publicUrl } = supabase.storage
            .from("chat_files")
            .getPublicUrl(filePath);

          if (!publicUrl) {
            throw new Error("Could not generate public URL.");
          }

          // Check the image for NSFW content
          const isNSFW = await checkForNSFWContent(publicUrl.publicUrl);
          if (isNSFW) {
            // If the content is NSFW, delete the file and notify the user
            const { error: deleteError } = await supabase.storage
              .from("chat_files")
              .remove([filePath]);

            if (deleteError) {
              console.error("Error deleting NSFW file:", deleteError);
            }

            setFile(null); // Clear the selected file
            setMessage(""); // Clear the message
            onUploadFeedback?.(
              "The image you are trying to upload is not appropriate. Please upload another image.",
            );
            // Set a timeout to clear the feedback message after 5 seconds
            setTimeout(() => {
              onUploadFeedback?.("");
            }, 5000);
            updateLoadingState(false);
            return;
          }

          // If the image is safe, move it to the permanent storage location
          const permanentFilePath = `${userId}/${sanitizedFileName}`;
          const { error: moveError } = await supabase.storage
            .from("chat_files")
            .move(filePath, permanentFilePath);

          if (moveError) {
            throw new Error(moveError.message);
          }
        } catch (error) {
          console.error("An error occurred during file processing:", error);
          onUploadFeedback?.(
            "An error occurred during file processing. Please try again.",
          );
        } finally {
          updateLoadingState(false);
        }
      } else {
        updateLoadingState(false);
      }
    }
  };

  const updateLoadingState = (isLoading: boolean) => {
    setUploading(isLoading);
    onLoadingChange?.(isLoading); // Call onLoadingChange if it's provided
  };

  const sendMessageToAiTutor = async (messageContent: string) => {
    updateLoadingState(true); // Start processing
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true, // defaults to process.env["OPENAI_API_KEY"]
    });
    try {
      let threadId;

      // Check for an existing thread mapping in Supabase
      const { data, error } = await supabase
        .from("student_thread_mappings")
        .select("thread_id")
        .eq("student_id", userId)
        .single();

      if (error && error.code !== "PGRST116") {
        // If error is not 'No rows found'
        console.error("Supabase Error:", error);
        throw error;
      }

      if (data) {
        // Existing thread found
        threadId = data.thread_id;
      } else {
        // No existing thread, create a new one
        const threadResponse = await openai.beta.threads.create(); // Assuming openai.createChat is a valid function
        const { error: insertError } = await supabase
          .from("student_thread_mappings")
          .insert([{ student_id: userId, thread_id: threadResponse.id }]);

        if (insertError) {
          console.error("Error storing new thread ID:", insertError);
          throw insertError;
        }

        threadId = threadResponse.id;
      }

      // Now that we have a threadId, send the message to the AWS Lambda endpoint
      await axios.post(AWS_LAMBDA_ENDPOINT, {
        student_id: userId,
        message: messageContent,
        thread_id: threadId,
      });

      setMessage("");
    } catch (error) {
      console.error("Error sending message to AI tutor:", error);
    } finally {
      updateLoadingState(false); // Processing complete
    }
  };

  useEffect(() => {
    // This will clear the timeout if the component is unmounted
    return () => clearTimeout(timeoutId);
  }, []);

  // ... when setting the timeout, keep a reference to it ...
  const timeoutId = setTimeout(() => {
    onUploadFeedback?.("");
  }, 5000);

  const sendMessage = async () => {
    if (message.trim() === "" && !file) {
      console.warn("Cannot send empty message or file");
      return;
    }

    if (chatId === "tutor") {
      await sendMessageToAiTutor(message);
      return;
    } else {
      // Check if the user has not confirmed for non-image files
      if (file && !isImageFile(file.name) && !confirmNoCopyright) {
        onUploadFeedback?.(
          "You must confirm that the file does not contain copyrighted material before uploading.",
        );
        setTimeout(() => onUploadFeedback?.(""), 5000);
        return;
      }

      updateLoadingState(true);

      // Directly call the OpenAI API for message moderation
      try {
        const moderationResponse = await fetch(
          "https://api.openai.com/v1/moderations",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`, // Securely retrieve your OpenAI API key
            },
            body: JSON.stringify({ input: message }),
          },
        );
        const moderationResult = await moderationResponse.json();
        // Check if the message is flagged as inappropriate
        if (moderationResult.results[0].flagged) {
          onUploadFeedback?.("Message not appropriate for posting.");
          setTimeout(() => onUploadFeedback?.(""), 5000);
          setMessage("");
          updateLoadingState(false);
          return;
        }
      } catch (error) {
        console.error("Error during message moderation:", error);
        onUploadFeedback?.("Failed to moderate message.");
        updateLoadingState(false);
      }
    }

    // If there's a file to send, handle the file upload
    const uniqueId = uuidv4();
    const sanitizedFileName = file
      ? uniqueId + "_" + sanitizeFileName(file.name)
      : null;

    if (file && sanitizedFileName) {
      updateLoadingState(true);
      const filePath = `${userId}/${sanitizedFileName}`;
      const { error: uploadError } = await supabase.storage
        .from("chat_files")
        .upload(filePath, file);

      if (uploadError) {
        console.error("Error uploading file:", uploadError);
        onUploadFeedback?.("Failed to upload file.");
        updateLoadingState(false);
        return;
      } else {
        onUploadFeedback?.("File uploaded successfully.");

        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        // Reset the file state
        setFile(null);
      }

      // Proceed to send the message with the file reference
      const { error: insertError } = await supabase.from("messages").insert([
        {
          chat_id: chatId,
          message_text: message,
          sender_id: userId,
          file_reference: filePath,
        },
      ]);

      updateLoadingState(false);
      if (insertError) {
        console.error("Error sending message:", insertError);
      } else {
        setMessage("");
        setFile(null);
        onUploadFeedback?.("");
      }
    } else {
      // If there is no file, just send the text message
      try {
        const { error } = await supabase.from("messages").insert([
          {
            chat_id: chatId,
            message_text: message,
            sender_id: userId,
          },
        ]);
        if (error) {
          throw error;
        }
        setMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
        onUploadFeedback?.("Failed to send message.");
      } finally {
        updateLoadingState(false);
      }
    }
  };

  const handleAction = (e: React.SyntheticEvent) => {
    e.preventDefault();
    updateLastActivity();
    sendMessage();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent the default action to avoid form submission
      sendMessage(); // Call the sendMessage function
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputText = e.target.value;
    if (inputText.length <= 500) {
      setMessage(inputText);
    } else {
      // Optionally, you can provide feedback to the user that the limit has been reached
      onUploadFeedback?.("Maximum character limit reached (500 characters).");
    }
  };

  return (
    <div className="flex items-center p-4 bg-gray-100 rounded-lg shadow dark:bg-gray-700">
      <form className="flex flex-grow" onSubmit={handleAction}>
        <input
          ref={fileInputRef}
          id="fileInput"
          type="file"
          accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
          className="hidden"
          onChange={handleFileChange}
        />
        {!isAIChat && (
          <label htmlFor="fileInput" className="m-2 mr-2 cursor-pointer">
            <AttachFileIcon className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300" />
          </label>
        )}
        <textarea
          className="flex-grow px-4 py-2 mb-5 overflow-hidden text-gray-700 bg-white rounded-md resize-none dark:text-gray-300 dark:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-300"
          placeholder="Type a message or attach a file..."
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          rows={1}
          style={{ lineHeight: "1.5", maxHeight: "150px" }}
        />
        <div className="self-end text-sm text-gray-600 dark:text-gray-400">
          {message.length}/500
        </div>
        <button
          type="submit"
          className="flex items-center justify-center px-4 py-2 ml-2 text-white bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-300"
        >
          <SendIcon />
        </button>
      </form>
      {file && !isImageFile(file?.name) && (
        <div className="flex items-center justify-between p-2 bg-gray-100 rounded-lg shadow">
          <span className="text-sm text-gray-600">
            I confirm this file does not contain copyrighted material.
          </span>
          <input
            type="checkbox"
            checked={confirmNoCopyright}
            onChange={(e) => setConfirmNoCopyright(e.target.checked)}
            className="w-5 h-5 ml-4 text-blue-600 form-checkbox"
          />
        </div>
      )}
    </div>
  );
};

export default ChatInput;
