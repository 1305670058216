import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import WelcomePage from "./components/WelcomePage/WelcomePage";

import { UserProvider } from "./components/UserContext";
import InitialScreen from "./InitialScreen";
import HomePage from "./components/HomePage/HomePage";
import Profile from "./components/Profile/Profile";
import Chat from "./components/Chat/Chat";
import { DarkModeProvider } from "./components/DarkModeContext";
import DocumentUpload from "./components/DocumentUpload/DocumentUpload";
import ResetPasswordPage from "./components/ResetPassword/ResetPasswordPage";
import SearchView from "./components/CommonComponents/SearchView";
import SignUpPage from "./components/WelcomePage/SignUpPage";
import UserAgreement from "./components/CommonComponents/UserAgreement";
import ConfirmEmail from "./components/WelcomePage/ConfirmEmail";
import RequireAuth from "./components/RequireAuth";
import { MessageNotificationProvider } from "./components/MessageNotificationContext";
import { SupabaseChatRoomProvider } from "./components/SupabaseChatRoomContext";

// Create a client
const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <DarkModeProvider>
          <SupabaseChatRoomProvider>
            <MessageNotificationProvider>
              <Router>
                <Routes>
                  <Route path="/" element={<InitialScreen />} />
                  <Route path="/welcome" element={<WelcomePage />} />
                  <Route path="/signup" element={<SignUpPage />} />
                  <Route path="/user-agreement" element={<UserAgreement />} />
                  <Route path="/reset" element={<ResetPasswordPage />} />

                  {/* Protected Routes */}
                  <Route
                    path="/homepage"
                    element={
                      <RequireAuth>
                        <HomePage />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/chat"
                    element={
                      <RequireAuth>
                        <Chat />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/confirm-email"
                    element={
                      <RequireAuth>
                        <ConfirmEmail />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/profile/:userId"
                    element={
                      <RequireAuth>
                        <Profile />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/search"
                    element={
                      <RequireAuth>
                        <SearchView />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/profile/"
                    element={
                      <RequireAuth>
                        <Profile />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/chat/first"
                    element={
                      <RequireAuth>
                        <Chat />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/chat/:chatId"
                    element={
                      <RequireAuth>
                        <Chat />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/search"
                    element={
                      <RequireAuth>
                        <SearchView />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/upload"
                    element={
                      <RequireAuth>
                        <DocumentUpload />
                      </RequireAuth>
                    }
                  />
                </Routes>
              </Router>
            </MessageNotificationProvider>
          </SupabaseChatRoomProvider>
        </DarkModeProvider>
      </UserProvider>
    </QueryClientProvider>
  );
};

export default App;
