// Chat.tsx
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ChatRoom from "./ChatRoom";
import ChatRoomList from "./ChatRoomList";
//import Sidebar from "../CommonComponents/Sidebar";
import { useDarkMode } from "../DarkModeContext";
import { SupabaseProvider } from "../SupabaseContext";
import { SupabaseAIMessagesProvider } from "../SupabaseAIMessagesContext";
import { SupabaseChatRoomProvider } from "../SupabaseChatRoomContext";
import { CircularProgress } from "@material-ui/core";
import { UserContext } from "../UserContext";
import { CourseUserCountProvider } from "../CourseUserCountContext";
import Navbar from "../CommonComponents/Navbar";
import supabase from "../../SupabaseClient";

const AI_TUTOR_CHAT_ID = "tutor";

const Chat = () => {
  const { chatId: routeChatId } = useParams<{ chatId?: string }>();
  const safeChatId = routeChatId || null;
  const { darkMode } = useDarkMode();
  const [selectedChatId, setSelectedChatId] = useState<string | null>(null);
  const userContext = useContext(UserContext);
  const [otherUsername, setOtherUsername] = useState("");
  const [otherUserId, setOtherUserId] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [chatName, setChatName] = useState("");
  const [otherUserAvatarUrl, setOtherUserAvatarUrl] = useState("");
  const locationState = location.state as {
    otherUsername?: string;
    otherUserId?: string;
  };

  if (!userContext) {
    console.error(
      "UserContext is null, this component must be rendered within a UserProvider",
    );
    return <CircularProgress />;
  }

  const handleBack = () => {
    setSelectedChatId(null); // Clear the selected chat room ID
    navigate("/chat/first"); // Navigate back to the chat room list route
  };

  const { userId, updateLastActivity, isMobile } = userContext;
  const isAiTutorChat = safeChatId === AI_TUTOR_CHAT_ID;

  // // Logic to determine if the chatroom list is showing
  // const showingChatRoomList =
  //   location.pathname.endsWith("/chat/first") || !selectedChatId;

  useEffect(() => {
    if (safeChatId) {
      setSelectedChatId(safeChatId);
    }
  }, [safeChatId]);

  useEffect(() => {
    if (
      locationState &&
      locationState.otherUsername &&
      locationState.otherUserId
    ) {
      setOtherUsername(locationState.otherUsername);
      setOtherUserId(locationState.otherUserId);
    }
  }, [locationState]);

  const handleChatroomSelect = async (
    chatId: string,
    otherUsername?: string,
    otherUserId?: string,
    otherUserAvatarUrl?: string,
  ) => {
    updateLastActivity();
    setSelectedChatId(chatId);
    setOtherUsername(otherUsername || "");
    setOtherUserId(otherUserId || "");
    setOtherUserAvatarUrl(otherUserAvatarUrl || "");

    if (chatId === AI_TUTOR_CHAT_ID) {
      setChatName("Brainy");
    } else {
      try {
        const { data, error } = await supabase.rpc(
          "get_chat_name_and_other_user_details",
          { param_chat_id: chatId, param_user_id: userId },
        );

        if (error) {
          console.error("Error fetching chat details:", error);
          return;
        }

        if (data && data.length > 0) {
          const chatDetails = data[0];
          // Update the chatName logic here
          let chatNameValue = chatDetails.chat_name;
          if (chatDetails.chat_name === "Direct Message") {
            chatNameValue = `${chatDetails.other_first_name} ${chatDetails.other_last_name}`;
          }

          setChatName(chatNameValue);
          // Other details
          setOtherUsername(chatDetails.other_user_name);
          setOtherUserId(chatDetails.other_user_id);
          setOtherUserAvatarUrl(chatDetails.other_user_avatar_url);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  //const chatContainerClasses = isMobile ? "mb-20" : "";

  return (
    <div
      className={`flex flex-col min-h-screen ${
        darkMode ? "bg-slate-800" : "bg-white"
      }`}
    >
      <Navbar />
      <div className="flex flex-col flex-grow overflow-hidden">
        <SupabaseChatRoomProvider>
          {isMobile && (!selectedChatId || routeChatId === "first") ? (
            // For mobile devices: Show chat room list if no specific chat room is selected or if /chat/first is accessed
            <ChatRoomList
              onChatroomSelect={handleChatroomSelect}
              darkMode={darkMode}
            />
          ) : (
            // For larger screens or if a specific chat room is selected on mobile
            <div className="flex flex-1 overflow-hidden">
              {!isMobile && (
                // Show chat room list on the side for larger screens
                <div className="w-1/4 overflow-y-auto bg-gray-200 dark:bg-gray-800">
                  <ChatRoomList
                    onChatroomSelect={handleChatroomSelect}
                    darkMode={darkMode}
                  />
                </div>
              )}
              <div className="flex flex-col w-full overflow-hidden lg:w-3/4">
                {(selectedChatId || (isMobile && routeChatId !== "first")) &&
                userId ? (
                  // Render the chat room if a chat is selected or if accessing a specific chat room directly on mobile
                  isAiTutorChat ? (
                    <SupabaseAIMessagesProvider studentId={userId}>
                      <div className="flex-1 overflow-y-auto">
                        <ChatRoom
                          chatroomId={selectedChatId || safeChatId}
                          isAiTutorChat={isAiTutorChat}
                          otherUsername={
                            !isAiTutorChat ? otherUsername : undefined
                          }
                          otherUserId={!isAiTutorChat ? otherUserId : undefined}
                          otherUserAvatarUrl={
                            !isAiTutorChat ? otherUserAvatarUrl : undefined
                          }
                          chatName={chatName}
                          onBack={isMobile ? handleBack : undefined}
                        />
                      </div>
                    </SupabaseAIMessagesProvider>
                  ) : (
                    <CourseUserCountProvider>
                      <SupabaseProvider
                        safeChatId={selectedChatId || safeChatId || ""}
                      >
                        <div className="flex-1 overflow-y-auto">
                          <ChatRoom
                            chatroomId={selectedChatId || safeChatId}
                            isAiTutorChat={isAiTutorChat}
                            otherUsername={
                              !isAiTutorChat ? otherUsername : undefined
                            }
                            otherUserId={
                              !isAiTutorChat ? otherUserId : undefined
                            }
                            otherUserAvatarUrl={
                              !isAiTutorChat ? otherUserAvatarUrl : undefined
                            }
                            chatName={chatName}
                            onBack={isMobile ? handleBack : undefined}
                          />
                        </div>
                      </SupabaseProvider>
                    </CourseUserCountProvider>
                  )
                ) : (
                  // Prompt to select a chat room if none is selected
                  <div className="flex items-center justify-center h-full dark:text-white">
                    Please select a chat room...
                  </div>
                )}
              </div>
            </div>
          )}
        </SupabaseChatRoomProvider>
      </div>
    </div>
  );
};

export default Chat;
