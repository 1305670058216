import { Button } from "@mui/material";

type BottomNavButtonsProps = {
  universityColor: string;
  buttonUrl: string;
  buttonText: string;
  darkMode: boolean;
};

const BottomNavButtons: React.FC<BottomNavButtonsProps> = ({
  universityColor,
  buttonUrl,
  buttonText,
  darkMode,
}) => {
  const textColor = darkMode ? "white" : "black";

  return (
    <div className="flex justify-center gap-2 sm:flex-row sm:gap-4">
      {/* Button for Portal UPR */}
      <Button
        href="https://portal.upr.edu/"
        target="_blank"
        rel="noopener noreferrer"
        className="px-4 py-2 text-sm font-medium rounded-lg shadow"
        sx={{
          backgroundColor: universityColor, // Always use the university color for the button background
          color: textColor, // Text color changes based on dark mode
          "&:hover": {
            backgroundColor: universityColor, // Keep the university color on hover
            opacity: 0.8, // Slight opacity change for a hover effect
          },
          textTransform: "none",
          borderRadius: "20px",
          fontSize: "1rem", // Larger font size
          padding: "10px 20px", // Increased padding for larger size// Prevents uppercase transform which is default for MUI buttons
        }}
      >
        Portal UPR
      </Button>

      {/* Button based on props */}
      <Button
        href={buttonUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="px-4 py-2 text-sm font-medium rounded-lg shadow"
        sx={{
          backgroundColor: universityColor, // Same university color for this button background
          color: textColor, // Text color changes based on dark mode
          "&:hover": {
            backgroundColor: universityColor, // Maintain background color on hover
            opacity: 0.8, // Slight opacity change for a hover effect
          },
          textTransform: "none",
          borderRadius: "20px",
          fontSize: "1rem", // Larger font size
          padding: "10px 20px", // Increased padding for larger size/ Prevents uppercase transform which is default for MUI buttons
        }}
      >
        {buttonText}
      </Button>

      {/* Button for Moodle */}
      <Button
        href="https://online.upr.edu/login/index.php"
        target="_blank"
        rel="noopener noreferrer"
        className="px-4 py-2 text-sm font-medium rounded-lg shadow"
        sx={{
          backgroundColor: universityColor, // Same university color for this button background
          color: textColor, // Text color changes based on dark mode
          "&:hover": {
            backgroundColor: universityColor, // Maintain background color on hover
            opacity: 0.8, // Slight opacity change for a hover effect
          },
          textTransform: "none",
          borderRadius: "20px",
          fontSize: "1rem", // Larger font size
          padding: "10px 20px", // Increased padding for larger size // Prevents uppercase transform which is default for MUI buttons
        }}
      >
        Moodle
      </Button>
    </div>
  );
};

export default BottomNavButtons;
