import React, { createContext, useState, useEffect, useCallback } from "react";
import { supabase } from "../SupabaseClient"; // Adjust this path to your Supabase client

interface CourseUserCountContextType {
  onlineUserCount: number;
  otherUserOnline: boolean | null;
  setChatDetails: (chatId: string, otherUserId: string) => void;
  setCurrentCourseId: (courseId: string) => void;
  fetchAndUpdateOnlineUserCount: (courseId: string) => Promise<void>;
  fetchOtherUserOnlineStatus: (chatId: string, userId: string) => Promise<void>;
}

export const CourseUserCountContext =
  createContext<CourseUserCountContextType | null>(null);

export const CourseUserCountProvider: React.FC = ({ children }) => {
  const [onlineUserCount, setOnlineUserCount] = useState<number>(0);
  const [otherUserOnline, setOtherUserOnline] = useState<boolean | null>(null);
  const [currentCourseId, setCurrentCourseId] = useState<string>("");
  const [currentChatId, setCurrentChatId] = useState<string>("");
  const [otherUserId, setOtherUserId] = useState<string>("");

  const setChatDetails = useCallback((chatId: string, otherUserId: string) => {
    setCurrentChatId(chatId);
    setOtherUserId(otherUserId);
  }, []);

  const fetchAndUpdateOnlineUserCount = useCallback(async (chatId: string) => {
    try {
      const { data, error } = await supabase.rpc(
        "get_online_user_count_in_course_chat",
        { course_chat_id: chatId },
      );

      if (error) {
        console.error("Error fetching online user count:", error);
        return;
      }

      setOnlineUserCount(data);
    } catch (error) {
      console.error("Error in fetchAndUpdateOnlineUserCount:", error);
    }
  }, []);

  const fetchOtherUserOnlineStatus = useCallback(
    async (chatId: string, userId: string) => {
      try {
        const { data, error } = await supabase.rpc(
          "get_online_status_in_direct_message",
          {
            param_chat_id: chatId,
            param_user_id: userId,
          },
        );

        if (error) {
          console.error("Error fetching other user's online status:", error);
          return;
        }

        setOtherUserOnline(data);
      } catch (error) {
        console.error("Error fetching other user's online status:", error);
      }
    },
    [],
  );

  useEffect(() => {
    if (!currentCourseId) return;

    const subscription = supabase
      .channel("custom-users-channel")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "users" },
        () => {
          setTimeout(() => {
            fetchOtherUserOnlineStatus(currentChatId, otherUserId);
            fetchAndUpdateOnlineUserCount(currentCourseId); // Refetch the online user count after a delay
          });
        },
      )
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, [currentChatId, otherUserId, fetchOtherUserOnlineStatus]);

  return (
    <CourseUserCountContext.Provider
      value={{
        onlineUserCount,
        otherUserOnline,
        setChatDetails,
        setCurrentCourseId,
        fetchAndUpdateOnlineUserCount,
        fetchOtherUserOnlineStatus,
      }}
    >
      {children}
    </CourseUserCountContext.Provider>
  );
};
