import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { useSupabaseEvents } from "../SupabaseEventsContext";
import { useDarkMode } from "../DarkModeContext";

const UpcomingEvents: React.FC = () => {
  const { events } = useSupabaseEvents();
  const { darkMode } = useDarkMode();

  const formatDuration = (start: string, end: string, all_day: boolean) => {
    if (all_day) {
      return "All day";
    }
    const durationMs = new Date(end).getTime() - new Date(start).getTime();
    const durationDays = Math.floor(durationMs / (1000 * 60 * 60 * 24));
    const durationHours = Math.floor(
      (durationMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const durationMinutes = Math.floor(
      (durationMs % (1000 * 60 * 60)) / (1000 * 60),
    );

    let duration = "";
    if (durationDays > 0) {
      duration += `${durationDays} day${durationDays > 1 ? "s" : ""} `;
    }
    if (durationHours > 0) {
      duration += `${durationHours} hour${durationHours > 1 ? "s" : ""} `;
    }
    if (durationMinutes > 0) {
      duration += `${durationMinutes} minute${durationMinutes > 1 ? "s" : ""}`;
    }

    return duration.trim();
  };

  if (events.length === 0) {
    return (
      <Typography
        variant="subtitle1"
        style={{
          textAlign: "center",
          marginTop: "20px",
          color: darkMode ? "white" : "black",
        }}
      >
        No upcoming events. Add an event to show it here.
      </Typography>
    );
  }

  return (
    <div>
      {events.map((event) => {
        const duration = formatDuration(event.start, event.end, event.all_day);
        return (
          <Card
            key={event.id}
            style={{
              backgroundColor: darkMode ? "#1A202C" : "#FFFFFF", // Use appropriate colors for dark/light mode
              color: darkMode ? "white" : "black",
              margin: "8px 0", // Add some margin between cards
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                style={{ color: darkMode ? "white" : "black" }}
              >
                {event.title}
              </Typography>
              <Typography style={{ color: darkMode ? "white" : "black" }}>
                {new Date(event.start).toLocaleString()} to{" "}
                {new Date(event.end).toLocaleString()}
              </Typography>
              <Typography style={{ color: darkMode ? "white" : "black" }}>
                Duration: {duration}
              </Typography>

              {event.type && (
                <Typography style={{ color: darkMode ? "white" : "black" }}>
                  Type:{" "}
                  {event.type.charAt(0).toUpperCase() +
                    event.type.slice(1).toLowerCase()}
                </Typography>
              )}
              {event.location && (
                <Typography style={{ color: darkMode ? "white" : "black" }}>
                  Location: {event.location}
                </Typography>
              )}
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default UpcomingEvents;
