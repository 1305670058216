import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../../SupabaseClient";

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [warning, setWarning] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [tokenHash, setTokenHash] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the token hash from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get("token");

    if (tokenParam) {
      setTokenHash(tokenParam);
    }
  }, []);

  const handleVerifyOtp = async () => {
    if (!tokenHash) {
      setWarning("Authorization token not found.");
      return;
    }

    const { error } = await supabase.auth.verifyOtp({
      token_hash: tokenHash,
      type: "email",
    });

    if (error) {
      setWarning("Invalid OTP or there was an error: " + error.message);
      return;
    }
    // The user is now authenticated and can update the password
    handleChangePassword();
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setWarning("Passwords do not match.");
      return;
    }

    const { error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) {
      setWarning("There was an error updating your password: " + error.message);
    } else {
      setSuccessMessage("Password updated successfully!");
      setTimeout(() => navigate("/welcome"), 3000); // Redirect to login page
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleVerifyOtp();
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
        <p className="mb-4 text-2xl font-semibold text-center">
          Password Reset
        </p>
        <div className="space-y-4">
          <form onSubmit={handleSubmit}>
            <input
              className="w-full p-2 border rounded"
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
              className="w-full p-2 border rounded"
              type="password"
              placeholder="Confirm New Password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            {warning && (
              <div className="p-2 text-center text-red-600">{warning}</div>
            )}
            {successMessage && (
              <div className="p-2 text-center text-green-600">
                {successMessage}
              </div>
            )}
            <button
              className="w-full p-2 text-white bg-blue-500 rounded hover:bg-blue-600"
              type="submit"
            >
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
