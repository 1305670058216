import { Link } from "react-router-dom";
import uprLogo from "../../assets/logo512.png";

const Header = () => {
  return (
    <header className="flex items-center justify-between px-4 py-2">
      <div className="flex items-center">
        {/* Wrap the image in a Link component */}
        <Link to="/welcome">
          <img src={uprLogo} alt="UPR Logo" className="h-20 cursor-pointer" />
        </Link>
        <span className="ml-2 text-4xl font-bold">UPRConnect</span>
      </div>
    </header>
  );
};

export default Header;
