import React, { useEffect, useRef, useState } from "react";
import dalle1 from "../../assets/DALLE1.png";
import dalle2 from "../../assets/DALLE2.png";
//import dalle3 from "../../assets/DALLE3.png";
//import dalle4 from "../../assets/DALLE4.png";
import uprLogo from "../../assets/logo512.png";
import dalle6 from "../../assets/DALLE6.png";
import supabase from "../../SupabaseClient";
import { useNavigate } from "react-router-dom";
import Header from "./Header";

interface FeatureSectionProps {
  title: string;
  description: string;
  imageSrc: string;
  reverse?: boolean;
}

const FeatureSection: React.FC<FeatureSectionProps> = ({
  title,
  description,
  imageSrc,
  reverse,
}) => {
  // Ref for the container div
  const sectionRef = useRef(null);
  const imageSection = (
    <div className="w-full p-4 md:w-1/2 animate-fadeIn">
      <img
        src={imageSrc}
        alt={title}
        className="w-full shadow-xl rounded-xl h-82"
      />
    </div>
  );

  const textSection = (
    <div className="w-full p-4 md:w-1/2 animate-fadeIn">
      <h3 className="text-2xl font-semibold">{title}</h3>
      <p className="mt-2 text-gray-600">{description}</p>
    </div>
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("reveal");
          }
        });
      },
      {
        threshold: 0.3,
      },
    );

    // Observe the ref
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Cleanup function
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className={`flex flex-col md:flex-row items-center justify-center p-8 gap-4 hidden-section ${
        reverse ? "md:flex-row-reverse" : ""
      }`}
    >
      {imageSection}
      {textSection}
    </div>
  );
};

const WelcomePage: React.FC = () => {
  const [showLoginForm, setShowLoginForm] = useState(true);

  const [showForgotPasswordForm, setForgotPasswordForm] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [warning, setWarning] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const resetForms = () => {
    setShowLoginForm(true);
    setForgotPasswordForm(false);
    setEmail("");
    setPassword("");
    setWarning("");
  };

  const navigate = useNavigate();

  const handleForgotPasswordSubmit = async () => {
    if (!email) {
      setWarning("Please fill in all required fields.");
      return;
    }
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: "https://uprconnect.com/reset",
    });

    if (error) {
      setWarning(error.message);
    } else {
      // navigate("/reset");
      setSuccessMessage("Reset Email Sent!");
      setTimeout(() => {
        setSuccessMessage("");
        resetForms();
      }, 3000);
    }
  };
  const handleLoginSubmit = async () => {
    if (!email || !password) {
      setWarning("Please fill in all required fields.");
      return;
    }

    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      setWarning(error.message);
    } else {
      setSuccessMessage("Login successful!");
      resetForms();
      navigate("/homepage");
      // Set the user's online status to true
      if (data) {
        setOnlineStatus(data.user.id, true);
      }
    }
  };

  const redirectToSignUp = () => {
    navigate("/signup");
  };

  const setOnlineStatus = async (userId: string, status: boolean) => {
    try {
      const { error } = await supabase.rpc("set_user_online_status", {
        user_id: userId,
        online_status: status,
      });

      if (error) {
        console.error("Error updating online status:", error);
      }
    } catch (err) {
      console.error("Error calling RPC:", err);
    }
  };

  const renderForm = () => {
    let formContent;
    if (showLoginForm) {
      formContent = (
        <form
          className="flex flex-col items-center space-y-4"
          onSubmit={(e) => {
            e.preventDefault();
            handleLoginSubmit();
          }}
        >
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full max-w-xs p-2.5 border border-gray-300 rounded-lg text-lg"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full max-w-xs p-2.5 border border-gray-300 rounded-lg text-lg"
          />
          {warning && <div className="text-red-500">{warning}</div>}
          <button
            type="submit"
            className="w-full max-w-xs p-2.5 text-lg text-white bg-blue-500 rounded-full"
          >
            Log in
          </button>
          <p
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() => setForgotPasswordForm(true)}
          >
            Forgot Password?
          </p>
        </form>
      );
    }
    if (showForgotPasswordForm) {
      formContent = (
        <div className="flex flex-col items-center space-y-4">
          <input
            className="w-full max-w-xs p-2.5 border border-gray-300 rounded-lg text-lg"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {warning && (
            <div className="mb-4 text-sm text-red-600">{warning}</div>
          )}
          <button
            className="w-full max-w-xs p-2.5 text-lg text-white bg-blue-500 rounded-full"
            onClick={handleForgotPasswordSubmit}
          >
            Send Reset Link
          </button>
          <button
            className="w-full max-w-xs p-2.5 text-lg text-white bg-blue-500 rounded-full"
            onClick={resetForms}
            type="button"
          >
            Cancel
          </button>
        </div>
      );
    }
    return (
      <div>
        {formContent}
        <div className="mt-4 text-center">
          <p>
            New to UPRConnect?{" "}
            <button
              onClick={redirectToSignUp}
              className="text-blue-600 hover:underline"
            >
              Sign up now
            </button>
          </p>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <footer className="flex items-center justify-between px-4 py-2 bg-white border-t">
        <span className="text-sm text-black">UPRConnect © 2023</span>
        <p>Created by Jan Faris, Yadiel Perez, and Alberto Cappa</p>
      </footer>
    );
  };

  return (
    <div className="flex flex-col h-screen bg-white">
      <Header />
      {/* Top Section - Form on the left, Logo on the right */}
      <div className="flex flex-row flex-2 ">
        {/* Left Section - Form */}
        <div className="flex flex-col items-center justify-center w-full p-16 bg-white md:w-1/2">
          {/* <h1 className="mb-2 text-4xl font-bold text-center">UPRConnect</h1> */}
          <p className="mb-4 text-xl text-center">
            Created by students, for students
          </p>
          {renderForm()}
          {successMessage && (
            <div
              className="p-4 mt-4 text-sm text-green-700 bg-green-100 rounded-lg"
              role="alert"
            >
              {successMessage}
            </div>
          )}
        </div>

        <div className="items-center justify-center hidden w-1/2 bg-white md:flex">
          <img
            src={uprLogo}
            alt="Welcome"
            className="rounded-full shadow-lg w-100 h-80"
          />
        </div>
      </div>

      {/* Bottom Section - Features */}
      <div className="bg-white ">
        <div className="container py-10 mx-auto">
          <FeatureSection
            title="Chatrooms for every course"
            description="Join course-specific discussions and collaborate with classmates. Additionally, engage in direct messaging with fellow students to connect on a more personal level. Discover common courses you share with others, fostering a community of learning and support."
            imageSrc={dalle1}
            reverse={false}
          />
          <FeatureSection
            title="Personalized assistance"
            description="Get help from Brainy, your AI-powered assistant."
            imageSrc={dalle6}
            reverse={true}
          />
          <FeatureSection
            title="Create and track events"
            description="Stay updated on your university events and never miss out."
            imageSrc={dalle2}
            reverse={false}
          />
        </div>
      </div>
      {renderFooter()}
    </div>
  );
};

export default WelcomePage;
