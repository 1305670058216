import React, { useContext, useEffect } from "react";
import { UserContext } from "./components/UserContext"; // Adjust the import path as necessary
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

const InitialScreen = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  if (!userContext) {
    throw new Error("InitialScreen must be used within a UserProvider");
  }

  const { userId, courseDetails, isInitialized } = userContext;

  useEffect(() => {
    if (isInitialized) {
      if (userId && courseDetails && courseDetails.length > 0) {
        navigate("/homepage"); // Adjust the route path as necessary
      } else {
        navigate("/welcome"); // User is not logged in, navigate to welcome page
      }
    } else {
      navigate("/welcome");
    }
  }, [userId, courseDetails, isInitialized, navigate]);

  if (!isInitialized) {
    return (
      <div className="flex items-center justify-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  // The component itself does not render anything; it's purely for navigation logic
  return null;
};

export default InitialScreen;
