import React, { useState, useEffect } from "react";

interface QuoteData {
  quote: string;
  author: string;
}

const MotivationalQuote: React.FC = () => {
  const [quoteData, setQuoteData] = useState<QuoteData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const currentTimestamp = new Date().getTime();

  useEffect(() => {
    const storedQuoteData = localStorage.getItem("motivationalQuoteData");
    const storedQuoteTimestamp = localStorage.getItem(
      "motivationalQuoteTimestamp",
    );

    if (
      storedQuoteData &&
      storedQuoteTimestamp &&
      currentTimestamp - parseInt(storedQuoteTimestamp) < 3600000
    ) {
      // Use the cached quote if less than an hour has passed
      const parsedData = JSON.parse(storedQuoteData);
      setQuoteData(parsedData);
      setIsLoading(false);
    } else {
      // Fetch a new quote if more than an hour has passed or no quote is cached
      fetchMotivationalQuote();
    }
  }, []);

  const fetchMotivationalQuote = async () => {
    const data = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: "You are a helpful assistant.",
        },
        {
          role: "user",
          content: "Give me a motivational quote for a university student.",
        },
      ],
    };

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify(data),
        },
      );

      const result = await response.json();
      const messageContent = result.choices[0].message.content;

      // Updated logic to parse the quote and author
      let quoteText = messageContent;
      let authorText = "Unknown";

      // Check if the message content includes a dash, indicating author's presence
      if (messageContent.includes(" - ")) {
        const parts = messageContent.split(" - ");
        quoteText = parts[0].trim();
        authorText = parts[1].trim();
      }

      const newQuoteData: QuoteData = {
        quote: quoteText.replace(/^"|"$/g, ""), // Removing leading and trailing quotes
        author: authorText,
      };
      setQuoteData(newQuoteData);
      localStorage.setItem(
        "motivationalQuoteData",
        JSON.stringify(newQuoteData),
      );
      localStorage.setItem(
        "motivationalQuoteTimestamp",
        currentTimestamp.toString(),
      );
    } catch (error) {
      console.error("Error fetching motivational quote:", error);
      setQuoteData({
        quote: "Stay motivated and keep learning!",
        author: "Unknown",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="inset-x-0 bottom-0 p-4 mb-4 bg-white rounded-lg shadow-lg dark:bg-gray-800 lg:bottom-8 lg:right-8 lg:left-8 lg:mb-0">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <blockquote className="text-sm italic text-gray-600 dark:text-gray-400 md:text-base">
            “{quoteData?.quote}”
          </blockquote>
          <p className="mt-2 text-xs text-right text-gray-500 dark:text-gray-300 md:text-sm">
            {quoteData?.author && `Author: ${quoteData.author}`}
          </p>
        </div>
      )}
    </div>
  );
};

export default MotivationalQuote;
