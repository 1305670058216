import React, { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../SupabaseClient";

// Adjusted interface to match ai_messages table structure
interface Message {
  file_reference: string | undefined;
  id: string;
  chat_id: string;
  message_text: string;
  msg_timestamp: string;
  sender_id: string;
  first_name: string;
  sender_type?: "user" | "ai";
}

interface SupabaseAIMessagesContextType {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}

const SupabaseAIMessagesContext = createContext<
  SupabaseAIMessagesContextType | undefined
>(undefined);

export const useSupabaseAIMessages = () => {
  const context = useContext(SupabaseAIMessagesContext);
  if (!context) {
    throw new Error(
      "useSupabaseAIMessages must be used within a SupabaseAIMessagesProvider",
    );
  }
  return context;
};

export const SupabaseAIMessagesProvider: React.FC<{ studentId: string }> = ({
  children,
  studentId,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  useEffect(() => {
    const aiMessagesWatcher = supabase
      .channel("custom-ai-messages-channel")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "ai_messages" },
        async () => {
          const { data } = await supabase.rpc("fetch_latest_ai_messages", {
            p_student_id: studentId,
          });
          if (data) {
            setMessages(data as Message[]);
          }
        },
      )
      .subscribe();

    return () => {
      aiMessagesWatcher.unsubscribe();
    };
  }, [studentId]);

  return (
    <SupabaseAIMessagesContext.Provider value={{ messages, setMessages }}>
      {children}
    </SupabaseAIMessagesContext.Provider>
  );
};
