import React, { useState, useEffect, useContext } from "react";
import supabase from "../../SupabaseClient";
import debounce from "lodash.debounce";
import { UserContext } from "../UserContext";
import { CircularProgress } from "@mui/material";
import { useDarkMode } from "../DarkModeContext";

interface User {
  id: string;
  username: string;
  university: string;
}

interface UserSearchProps {
  onUserSelect: (userId: string, username: string) => void; // Function to call when a user is selected
}

const UserSearch: React.FC<UserSearchProps> = ({ onUserSelect }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const userContext = useContext(UserContext);
  const { darkMode } = useDarkMode();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  if (!userContext) {
    return <CircularProgress />;
  }

  const { userId, isLoadingUser } = userContext;

  if (isLoadingUser || !userId) {
    return <CircularProgress />;
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowDown") {
        setSelectedIndex((prevIndex) =>
          prevIndex === null || prevIndex >= searchResults.length - 1
            ? 0
            : prevIndex + 1,
        );
      } else if (e.key === "ArrowUp") {
        setSelectedIndex((prevIndex) =>
          prevIndex === null || prevIndex <= 0
            ? searchResults.length - 1
            : prevIndex - 1,
        );
      } else if (e.key === "Enter" && selectedIndex !== null) {
        const selectedUser = searchResults[selectedIndex];
        if (selectedUser) {
          onUserSelect(selectedUser.id, selectedUser.username);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchResults, selectedIndex]);

  const performSearch = debounce(async (query: string) => {
    const { data, error } = await supabase
      .from("users")
      .select("username, id, university")
      .ilike("username", `%${query}%`)
      .eq("allow_direct_messages", true)
      .eq("allow_profile_search", true)
      .neq("id", userId);

    if (error) {
      console.error("Error in search:", error);
      return;
    }

    setSearchResults(data);
  }, 300);

  useEffect(() => {
    if (searchTerm) {
      performSearch(searchTerm);
    } else {
      setSearchResults([]);
    }

    return () => {
      performSearch.cancel();
    };
  }, [searchTerm]);

  const handleUserSelect = (userId: string, username: string) => {
    onUserSelect(userId, username);
    setSearchTerm(""); // Clear the search term
    setSearchResults([]); // Optionally clear the search results as well
  };

  return (
    <div className="relative">
      <input
        type="text"
        placeholder="Find classmates by username..."
        className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring focus:border-blue-300 dark:border-gray-700 dark:bg-gray-800 dark:text-white"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {searchResults.length > 0 && (
        <div
          className={`absolute z-10 w-full max-h-66 overflow-y-auto border border-gray-300 rounded-md shadow-lg ${
            darkMode ? "dark:bg-gray-700 dark:border-gray-600" : "bg-white"
          }`}
        >
          {searchResults.map((user) => (
            <div
              key={user.id}
              className={`px-4 py-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 ${
                darkMode ? "dark:text-white" : "text-gray-900"
              }`}
              onClick={() => handleUserSelect(user.id, user.username)}
            >
              <div className="font-semibold">{user.username}</div>
              <div className="text-sm">
                {user.university || "No university info"}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserSearch;
