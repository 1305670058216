import React, { useState } from "react";
import { DateTime } from "luxon";
import { supabase } from "../../SupabaseClient";
import { useDarkMode } from "../DarkModeContext";
import { FaEye } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";

interface MessageProps {
  id: string;
  text: string;
  sender: string;
  timestamp?: string;
  file_reference?: string;
  className?: string; // Add this line for className prop
  sender_type?: "user" | "ai";
}

const Message: React.FC<MessageProps> = ({
  text,
  sender,
  timestamp,
  file_reference,
  className,
}) => {
  const [filePreview, setFilePreview] = useState<JSX.Element | null>(null);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const downloadFileDocument = async (
    fileReference?: string,
  ): Promise<void> => {
    if (fileReference) {
      try {
        // Fetch the file from Supabase Storage
        const { data, error } = await supabase.storage
          .from("chat_files")
          .download(fileReference);
        if (error) {
          console.error("Error downloading file:", error);
          return;
        }

        // Convert the file to a data URI
        const blob = new Blob([data as BlobPart]);
        const dataURI = URL.createObjectURL(blob);

        // Create a temporary anchor element to trigger the download
        const link = document.createElement("a");
        link.href = dataURI;
        link.download = fileReference.split("/")[1]; // Set the desired filename for the downloaded file
        link.click();

        // Remove the temporary anchor element
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  };

  const extractLocalHourAndMinute = (utcTimeString?: string): string | null => {
    if (utcTimeString) {
      const date = DateTime.fromISO(utcTimeString, { zone: "utc" }); // Create a Luxon DateTime in UTC
      const localDate = date.toLocal(); // Convert to the user's local time zone

      const formattedTime = localDate.toFormat("h:mm a"); // Format as 12-hour time with AM/PM

      return formattedTime;
    }
    return null;
  };
  const formatText = (inputText: string): JSX.Element[] => {
    return inputText.split("**").map((segment, index) => {
      return index % 2 === 0 ? (
        <span key={index}>{segment}</span>
      ) : (
        <strong key={index} className="font-bold">
          {segment}
        </strong>
      );
    });
  };

  const parseStructuredMessage = (text: string): JSX.Element[] => {
    return text.split("\n").map((line, index) => {
      if (line.startsWith("**")) {
        return (
          <div key={index} className="mt-2 mb-1">
            {formatText(line)}
          </div>
        );
      } else if (line.startsWith("-")) {
        return (
          <li key={index} className="ml-4 list-disc list-inside">
            {line.substring(1).trim()}
          </li>
        );
      } else {
        return <p key={index}>{line}</p>;
      }
    });
  };

  const getFilePreview = async (file_reference: string) => {
    if (!file_reference) return;

    const fileName = file_reference.split("/").pop();
    const fileExtension = fileName?.split(".").pop()?.toLowerCase();

    if (fileExtension === "pdf") {
      // For PDF files, generate a signed URL and use an iframe to display the preview
      const { data, error } = await supabase.storage
        .from("chat_files")
        .createSignedUrl(file_reference, 60); // URL valid for 60 seconds

      if (error) {
        console.error("Error creating signed URL:", error.message);
        return;
      }

      setFilePreview(
        <iframe
          src={data.signedUrl}
          title="PDF preview"
          className="w-full"
          frameBorder="0"
        ></iframe>,
      );
    } else if (["jpg", "jpeg", "png", "gif"].includes(fileExtension || "")) {
      // For image files, retrieve the public URL
      const { data } = supabase.storage
        .from("chat_files")
        .getPublicUrl(file_reference);

      setFilePreview(
        <img
          src={data.publicUrl}
          alt="file preview"
          className="max-w-80 max-h-80 rounded-2xl"
        />,
      );
    } else {
      // For other file types, show a generic file icon or message
      setFilePreview(<p>File preview not available</p>);
    }
  };
  const togglePreview = async (fileReference: string) => {
    if (!showPreview) {
      // Only get the preview if we are going to show it
      await getFilePreview(fileReference);
    }
    setShowPreview(!showPreview);
  };

  const { darkMode } = useDarkMode();
  const hourAndMinute = extractLocalHourAndMinute(timestamp);
  const messageClasses = `items-center mb-4 ${className || ""} ${
    darkMode ? "dark:bg-gray-700 dark:text-gray-300" : "bg-white text-gray-800"
  }`;
  return (
    <div className={messageClasses}>
      <span
        className={`font-semibold text-md ${
          darkMode ? "dark:text-gray-200" : "text-gray-900"
        }`}
      >
        {sender}
      </span>
      <span
        className={`pl-1 text-sm ${
          darkMode ? "dark:text-gray-400" : "text-gray-600"
        }`}
      >
        {hourAndMinute}
      </span>
      <br />

      {/* Use a div or fragment instead of p */}
      <div className={`${darkMode ? "dark:text-gray-200" : "text-gray-800"}`}>
        {parseStructuredMessage(text)}
      </div>

      {file_reference && file_reference !== "NULL" && (
        <div className="flex justify-end space-x-1">
          {/* Toggle button for file preview */}
          {showPreview && filePreview}
          <button onClick={() => togglePreview(file_reference)} title="Preview">
            <FaEye size={20} />
          </button>
          {/* Conditional rendering of the file preview */}

          <button
            onClick={(e) => {
              e.preventDefault();
              downloadFileDocument(file_reference);
            }}
            title="Download"
          >
            <IoMdDownload size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

export default Message;
