// DocumentUpload.tsx
import React, { useContext, useState } from "react";
import supabase from "../../SupabaseClient";
import { useNavigate } from "react-router-dom";
import exampleImage from "../../assets/example-screenshot.png";
import { CircularProgress } from "@material-ui/core";
import { UserContext } from "../UserContext";

interface Course {
  id: string;
  course_code: string;
}

const universityCodeMap = {
  UPRM: "UPRM",
  UPRRP: "UPRRP",
  UPRB: "UPRB",
  UPRAG: "UPRAG",
  UPRA: "UPRA",
  UPRCA: "UPRCA",
  UPRC: "UPRC",
  UPRH: "UPRH",
  UPRP: "UPRP",
  UPRU: "UPRU",
};

const DocumentUpload: React.FC = () => {
  const [courseDocument, setCourseDocument] = useState<File | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const userContext = useContext(UserContext);

  if (!userContext) {
    console.error(
      "UserContext is null, this component must be rendered within a UserProvider",
    );
    return <CircularProgress />;
  }

  const { userId, userDetails } = userContext;
  const navigate = useNavigate();

  if (!userId) {
    navigate("/welcome");
  }

  const handleCourseDocSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setCourseDocument(file);
    }
  };
  const getCurrentTermCode = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // January is 0, so we add 1

    let semester = "S1"; // default semester
    if (month >= 1 && month <= 5) {
      semester = "S2";
    }

    return `${year}${semester}`;
  };
  const detectText = async (imageUri: string) => {
    const googleVisionApiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";
    const url = `https://vision.googleapis.com/v1/images:annotate?key=${googleVisionApiKey}`;
    const requestData = {
      requests: [
        {
          image: { source: { imageUri } },
          features: [{ type: "TEXT_DETECTION", maxResults: 50 }],
        },
      ],
    };
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestData),
    });
    const data = await response.json();
    return data;
  };

  const verifyCoursesWithCampus = async (
    courses: string,
    campusCode: string,
  ) => {
    const verifiedCourses = [];
    for (const courseCode of courses) {
      const { data, error } = await supabase
        .from("courses")
        .select("id")
        .eq("course_code", courseCode)
        .eq("campus", campusCode)
        .single();

      if (error) {
        console.error(`Error verifying course ${courseCode}:`, error.message);
      } else if (data) {
        verifiedCourses.push({ course_code: courseCode, id: data.id });
      }
    }
    return verifiedCourses;
  };

  const handleUpload = async () => {
    setIsProcessing(true);
    setIsError(false);

    if (courseDocument && userId) {
      const university = userDetails?.university;
      const campusCode =
        universityCodeMap[university as keyof typeof universityCodeMap] ||
        "UNKNOWN";

      const fileExtension = courseDocument.name.split(".").pop();
      const newFileName = `${userId}_${Date.now()}.${fileExtension}`;
      const filePath = `${userId}/${newFileName}`;
      const { error: uploadError } = await supabase.storage
        .from("course_documents")
        .upload(filePath, courseDocument);

      if (uploadError) {
        console.error(uploadError.message);
        setIsProcessing(false);
        return;
      }

      const courseDocUrl = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/course_documents/${filePath}`;

      // Update the courseDocUrl in the users table
      const { error: updateError } = await supabase
        .from("users")
        .update({ course_doc_url: courseDocUrl })
        .eq("id", userId);

      if (updateError) {
        console.error("Error updating courseDocUrl:", updateError.message);
        setIsProcessing(false);
        return;
      }

      const data = await detectText(courseDocUrl);
      const courseRegex = /\b[A-Z]{4}\d{4}\b/g;
      const courses =
        data.responses[0]?.fullTextAnnotation?.text.match(courseRegex);

      if (courses && courses.length > 0) {
        const { error } = await supabase
          .from("courses")
          .select("id, course_code")
          .in("course_code", courses);

        if (error) {
          console.error(error.message);
          setIsProcessing(false);
          return;
        }
        const verifiedCourses = await verifyCoursesWithCampus(
          courses,
          campusCode,
        );

        const termCode = getCurrentTermCode();

        const records = verifiedCourses.map((course: Course) => {
          return {
            student_id: userId,
            course_id: course.id,
            term_code: termCode,
            campus: campusCode,
            course_code: course.course_code, // Uncomment if you want to include it in the record
          };
        });

        const { error: insertError } = await supabase
          .from("student_courses")
          .upsert(records);

        if (insertError) {
          console.error("Error upserting records:", records);
          console.error("Error upserting courses:", insertError.message);
          setIsProcessing(false);
          return;
        }
        setIsProcessing(false);
        userContext.setCourseDetails(verifiedCourses);
        setTimeout(() => {
          navigate("/homepage");
        }, 500);
      } else {
        // OCR was not successful, delete the uploaded file from Supabase
        const { error: deleteError } = await supabase.storage
          .from("course_documents")
          .remove([filePath]);

        if (deleteError) {
          console.error("Error deleting the file:", deleteError.message);
        }

        console.warn(
          "No courses found in the document. Please upload a clearer image.",
        );
        setIsError(true); // Set the error state
        setIsProcessing(false);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-4 bg-gray-100">
      <div className="max-w-2xl p-8 mx-auto bg-white rounded-lg shadow-md">
        <h2 className="mb-4 text-xl font-bold text-gray-700">
          Upload Your Current Semester Course Enrollment ({getCurrentTermCode()}
          )
        </h2>
        <p className="mb-4 text-gray-600">
          Please upload a clear and legible screenshot or photo of your current
          semester course enrollment. Make sure all course names and codes are
          visible.
        </p>
        <p className="mb-4 text-gray-600">
          <strong>Example of a good image:</strong>
        </p>
        {/* Assuming you will replace 'exampleImage' with the actual image path */}
        <img
          src={exampleImage}
          alt="Example enrollment screenshot"
          className="h-auto max-w-full mb-4 rounded"
        />
        <div className="mb-4">
          <input
            type="file"
            accept=".jpg,.png"
            onChange={handleCourseDocSelect}
            className="p-2 border border-blue-500 rounded"
          />
        </div>
        {courseDocument && (
          <button
            onClick={handleUpload}
            className="px-4 py-2 mb-4 text-white bg-blue-500 rounded-lg hover:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200"
          >
            Upload and Process Document
          </button>
        )}
        {isProcessing && (
          <div className="flex items-center justify-center mt-4">
            <div className="w-12 h-12 ease-linear border-4 border-t-4 border-gray-200 rounded-full loader"></div>
            <span className="ml-2 text-blue-500">
              Processing your image, please wait...
            </span>
          </div>
        )}
        {isError && (
          <div className="mt-4 text-red-600">
            Image processing failed. Please upload a clearer image with your
            courses and try again.
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentUpload;
