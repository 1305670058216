import React, { useContext, useEffect, useState } from "react";
import { supabase } from "../../SupabaseClient";
import Message from "./Message";
import ChatInput from "./ChatInput";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, CircularProgress } from "@material-ui/core";
import { useSupabase } from "../SupabaseContext";
import { useDarkMode } from "../DarkModeContext";
import { UserContext } from "../UserContext";
import { v4 as uuidv4 } from "uuid";
import { useSupabaseAIMessages } from "../SupabaseAIMessagesContext";
import { BounceLoader } from "react-spinners";
import { CourseUserCountContext } from "../CourseUserCountContext";
import { IoIosArrowBack } from "react-icons/io";
import { useMessageNotification } from "../MessageNotificationContext";

interface Message {
  id: string;
  chat_id: string;
  message_text: string;
  msg_timestamp: string;
  sender_id: string;
  first_name: string;
  file_reference: string; // Add a field to store file references
  sender_type?: "user" | "ai";
}

interface SupabaseError {
  message: string;
  details?: string;
  hint?: string;
  code?: string;
}

interface ChatRoomProps {
  chatroomId: string | null;
  isAiTutorChat?: boolean;
  otherUsername?: string;
  otherUserId?: string;
  otherUserAvatarUrl?: string;
  chatName?: string;
  onBack?: () => void;
}

interface Course {
  course_code: string;
  // Add other properties if needed
}

const formatDate = (timestamp: string): string => {
  const date = new Date(timestamp);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

const isDifferentDay = (time1: string, time2: string): boolean => {
  return formatDate(time1) !== formatDate(time2);
};

const ChatRoom: React.FC<ChatRoomProps> = ({
  chatroomId,
  isAiTutorChat,
  otherUsername,
  otherUserId,
  otherUserAvatarUrl,
  chatName,
  onBack,
}) => {
  const { chatId: routeChatId } = useParams<{ chatId: string }>();
  const safeChatId = chatroomId || routeChatId;
  const chatContainerRef = React.useRef<HTMLDivElement>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [noMoreMessages, setNoMoreMessages] = useState(false);
  const userContext = useContext(UserContext);
  const { markNotificationAsRead, notifications } = useMessageNotification();
  const courseUserCountContext = useContext(CourseUserCountContext);
  const { darkMode } = useDarkMode();

  if (!userContext) {
    console.error(
      "UserContext is null, this component must be rendered within a UserProvider",
    );
    return <CircularProgress />;
  }

  useEffect(() => {
    if (safeChatId && courseUserCountContext) {
      courseUserCountContext.fetchAndUpdateOnlineUserCount(safeChatId);
    }
  }, [safeChatId, courseUserCountContext]);

  useEffect(() => {
    if (safeChatId && courseUserCountContext && userId) {
      courseUserCountContext.fetchOtherUserOnlineStatus(safeChatId, userId);
    }
  }, [safeChatId, courseUserCountContext, otherUserId]);

  const { userId, isMobile } = userContext;
  const messagesHook = isAiTutorChat ? useSupabaseAIMessages() : useSupabase();
  const { messages, setMessages } = messagesHook;
  const otherUserOnline = courseUserCountContext?.otherUserOnline;
  const [, setChatName] = useState("");
  const [uploadFeedback, setUploadFeedback] = useState("");

  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [commonCourses, setCommonCourses] = useState([]);

  const navigate = useNavigate();

  // Handler to navigate to the user's profile
  const goToUserProfile = () => {
    if (otherUserId) {
      navigate(`/profile/${otherUserId}`);
    }
  };

  const handleInputFocus = () => {
    setKeyboardVisible(true); // Update this line
  };

  const handleInputBlur = () => {
    setKeyboardVisible(false); // Update this line
  };

  useEffect(() => {
    const fetchCommonCourses = async () => {
      if (!isAiTutorChat && userId && otherUserId) {
        try {
          const { data, error } = await supabase.rpc("get_common_courses", {
            student_id_1: userId,
            student_id_2: otherUserId,
          });
          if (error) throw error;
          setCommonCourses(data);
        } catch (error) {
          console.error("Error fetching common courses:", error);
        }
      }
    };

    fetchCommonCourses();
  }, [userId, otherUserId, isAiTutorChat]);

  // Define a callback function to update the feedback message
  const handleUploadFeedback = (message: string) => {
    setUploadFeedback(message);
  };

  useEffect(() => {
    if (isAiTutorChat) {
      setChatName("Brainy");
    }
  }, [isAiTutorChat, otherUsername, safeChatId]);

  const onScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    if (target.scrollTop === 0 && !isFetching) {
      setIsFetching(true);

      let data: Message[] = [];
      let error: SupabaseError | null = null;
      if (isAiTutorChat) {
        ({ data, error } = await supabase.rpc("fetch_older_ai_messages", {
          p_student_id: userId,
          p_offset: messages.length,
        }));
      } else {
        ({ data, error } = await supabase.rpc("fetch_older_messages", {
          p_chat_id: safeChatId,
          p_offset: messages.length,
        }));
      }

      if (data.length === 0) {
        setNoMoreMessages(true);
      }
      if (error) {
        console.error("Error fetching older messages:", error);
      } else {
        setMessages((prevMessages) => [...prevMessages, ...data]);
      }
      setIsFetching(false);
      target.scrollTop = 1;
    }
  };

  useEffect(() => {
    if (noMoreMessages) {
      const timer = setTimeout(() => {
        setNoMoreMessages(false);
      }, 2000); // 2000 milliseconds = 2 seconds

      return () => clearTimeout(timer); // Clear the timer if the component is unmounted
    }
  }, [noMoreMessages]);

  const markMessagesAsRead = async (messageIds: string[]) => {
    try {
      const { data: existingReads } = await supabase
        .from("user_message_reads")
        .select("message_id")
        .in("message_id", messageIds)
        .eq("user_id", userContext.userId);

      // Ensure existingReads is treated as an empty array if it's null
      const filteredExistingReads = existingReads || [];

      // Filter out the message IDs that have already been read
      const newReads = messageIds.filter(
        (messageId) =>
          !filteredExistingReads.some((read) => read.message_id === messageId),
      );

      // Only proceed if there are new reads to mark
      if (newReads.length > 0) {
        const updates = newReads.map((messageId) => ({
          user_id: userContext.userId,
          message_id: messageId,
          read_at: new Date().toISOString(),
        }));

        const { error } = await supabase
          .from("user_message_reads")
          .insert(updates);

        if (error) {
          throw error;
        }
      }
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (!safeChatId) return;

      if (isAiTutorChat) {
        try {
          const { data, error } = await supabase.rpc(
            "fetch_latest_ai_messages",
            {
              p_student_id: userId,
            },
          );
          if (error) {
            throw error;
          }

          if (data.length === 0) {
            // No messages found, display a welcome message
            setMessages([
              {
                id: uuidv4(),
                chat_id: safeChatId,
                message_text:
                  "Welcome! My name is Brainy. Ask me any doubts related to your courses to get started.",
                msg_timestamp: new Date().toISOString(),
                sender_id: "brainy", // A fixed ID for the AI tutor
                file_reference: "", // Add a field to store file references
                first_name: "Brainy",
              },
            ]);
          } else {
            setMessages(data);
          }
        } catch (error) {
          console.error("Error fetching AI messages:", error);
        }
      } else {
        const { data, error } = await supabase.rpc("fetch_latest_messages", {
          p_chat_id: safeChatId,
        });

        if (error) {
          console.error("Error fetching messages:", error);
        } else {
          const typedData = data as Message[];

          setMessages(typedData);
          // Call markMessagesAsRead with the IDs of the fetched messages
          notifications
            .filter(
              (notification) =>
                notification.chat_id === safeChatId && !notification.read,
            )
            .forEach((notification) => {
              markNotificationAsRead(notification.notification_id);
            });

          const messageIds = typedData.map((msg) => msg.id);
          markMessagesAsRead(messageIds);
        }
      }
    };

    fetchMessages();
  }, [
    isAiTutorChat,
    safeChatId,
    userId,
    setMessages,
    notifications,
    markNotificationAsRead,
  ]);

  const handleLoadingChange = (isLoading: boolean) => {
    setIsFetching(isLoading); // Assuming you want to use the same state
  };

  const renderOnlineUsersCount = () => {
    // If it's a general chat room and not a direct message, show the total online users count
    if (!isAiTutorChat && !otherUserId) {
      return (
        <div className="text-right">
          <div className="flex items-center justify-end space-x-2">
            <div className="text-green-500">
              <BounceLoader color={"#10b981"} size={15} />
            </div>
            <span
              className={`px-3 py-1 text-sm bg-gray-200 rounded-full ${
                darkMode ? "dark:bg-gray-700 dark:text-green-300" : ""
              }`}
            >
              {courseUserCountContext?.onlineUserCount} students online
            </span>
          </div>
        </div>
      );
    }
    // If it's a direct message, show the other user's online status
    if (!isAiTutorChat && otherUserId) {
      return (
        <div className="text-right">
          <div className="flex items-center justify-end space-x-2">
            <div className={`text-${otherUserOnline ? "green" : "red"}-500`}>
              <BounceLoader
                color={otherUserOnline ? "#10b981" : "#ff0000"}
                size={15}
              />
            </div>
            <span
              className={`px-3 py-1 text-sm bg-gray-200 rounded-full ${
                darkMode
                  ? "dark:bg-gray-700 dark:text-" +
                    (otherUserOnline ? "green" : "red") +
                    "-300"
                  : ""
              }`}
            >
              {chatName} is {otherUserOnline ? "Online" : "Offline"}
            </span>
          </div>
        </div>
      );
    }

    // If neither condition is met, return null (nothing to render)
    return null;
  };

  const ChatRoomHeader = () => {
    return (
      <div
        className={`px-3 pt-0 flex justify-between items-center rounded-sm
         ${isMobile ? "pt-0" : ""}`}
        style={{
          height: "80px",
          backgroundColor: darkMode ? "#1a202c" : "#f7fafc",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        <div className="flex items-center">
          {otherUserAvatarUrl && (
            <Avatar
              src={otherUserAvatarUrl}
              alt={`${otherUsername}'s avatar`}
              className="w-20 h-20 mr-4"
              onClick={goToUserProfile}
              style={{ width: "70px", height: "70px" }}
            />
          )}
          <div>
            <h2
              className={`text-xl font-bold ${
                darkMode ? "text-white" : "text-black"
              } ${otherUserId ? "cursor-pointer" : ""}`}
              onClick={otherUserId ? goToUserProfile : undefined}
            >
              {chatName} {/* Display the chat name */}
            </h2>
            {otherUserId && <CommonCourses courses={commonCourses} />}
          </div>
        </div>
        <div>{renderOnlineUsersCount()}</div>
      </div>
    );
  };

  const CommonCourses = ({ courses }: { courses: Course[] }) => {
    if (!courses || courses.length === 0) return null;

    return (
      <div className="inline-block px-4 py-1.5 text-xs font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">
        <span className="mr-1">Common Courses:</span>
        {courses.map((course, index) => (
          <span
            key={index}
            className="after:content-['·'] after:mx-1 last:after:content-['']"
          >
            {course.course_code}
          </span>
        ))}
      </div>
    );
  };

  return (
    <div
      className={`flex flex-col ${darkMode ? "dark" : ""}`}
      style={{ height: `calc(100vh - 64px)` }} // Adjust 64px to your navbar's actual height
    >
      {isMobile && (
        <div className="flex items-center justify-between p-4">
          <button
            onClick={onBack}
            className="text-2xl text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-white"
          >
            <IoIosArrowBack />
          </button>
        </div>
      )}

      {isFetching && (
        <div className="fixed z-50 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          <BounceLoader color={darkMode ? "#ffffff" : "#000000"} />
        </div>
      )}
      {uploadFeedback && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="px-4 py-2 text-white bg-black rounded">
            {uploadFeedback}
          </div>
        </div>
      )}

      <ChatRoomHeader />
      {noMoreMessages && (
        <div className="p-2 text-center text-blue-700 bg-blue-100 dark:bg-blue-900 dark:text-blue-200">
          No more older messages
        </div>
      )}
      <div
        className={`flex-1 p-4 overflow-y-auto scrollbar-thin ${
          darkMode ? "scrollbar-thumb-dark scrollbar-track-dark" : ""
        } ${keyboardVisible ? "pb-20" : "pb-2"}`} // Adjust padding based on keyboard visibility
        ref={chatContainerRef}
        onScroll={onScroll}
      >
        {messages &&
          [...messages].reverse().map((msg, index, arr) => (
            <React.Fragment
              key={`${msg.id}-${msg.msg_timestamp}-${index}-fragment`}
            >
              {(index === 0 ||
                isDifferentDay(
                  msg.msg_timestamp,
                  arr[index - 1]?.msg_timestamp,
                )) && (
                <div className="flex justify-center my-4">
                  <div className="bg-black dark:bg-gray-700 rounded-full text-center py-1.5 px-4 font-bold text-white shadow-md inline-block">
                    {formatDate(msg.msg_timestamp)}
                  </div>
                </div>
              )}

              <div
                className={`flex w-full my-2 ${
                  msg.sender_id === userId || msg.sender_type === "user"
                    ? "justify-end"
                    : "justify-start"
                }`}
                key={`${msg.id}-${msg.msg_timestamp}-${index}-wrapper`}
              >
                <Message
                  className={`w-1/2 border border-gray-300 ${
                    msg.sender_id === userId
                      ? "rounded-lg rounded-bl-none p-4 self-end bg-white shadow-lg"
                      : "rounded-lg rounded-br-none p-4  bg-white shadow-lg"
                  }`}
                  sender={
                    msg.sender_type === "ai"
                      ? "Brainy"
                      : msg.first_name || "You"
                  }
                  key={`${msg.id}-${msg.msg_timestamp}-${index}`}
                  {...msg}
                  text={msg.message_text}
                  timestamp={msg.msg_timestamp}
                  file_reference={msg.file_reference}
                />
              </div>
            </React.Fragment>
          ))}
      </div>
      <div
        className={`w-full px-4 py-2`}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      >
        {safeChatId && (
          <ChatInput
            chatId={safeChatId}
            isAIChat={isAiTutorChat}
            onLoadingChange={handleLoadingChange}
            onUploadFeedback={handleUploadFeedback}
          />
        )}
      </div>
    </div>
  );
};

export default ChatRoom;
