import React from "react";
import UserSearch from "./UserSearch";
//import Sidebar from "./Sidebar";
import { useDarkMode } from "../DarkModeContext";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

const SearchView: React.FC = () => {
  const { darkMode } = useDarkMode();
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col min-h-screen ${
        darkMode ? "bg-slate-800" : "bg-gray-100"
      }`}
    >
      <Navbar />
      {/* <Sidebar className="w-16 lg:w-16" /> */}

      <div className="flex-grow p-8 lg:ml-6">
        <h1
          className={`mb-6 text-2xl font-semibold ${
            darkMode ? "text-white" : "text-gray-800"
          }`}
        >
          Search for other students
        </h1>
        <div
          className={`${
            darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
          } p-6 rounded-lg shadow`}
        >
          <UserSearch
            onUserSelect={(userId) => navigate(`/profile/${userId}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchView;
