// src/contexts/UserContext.tsx
import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import { supabase } from "../SupabaseClient";

interface Course {
  id: string;
  name?: string;
  description?: string;
  course_code: string;
}

interface UniversityData {
  university: string;
  color: string;
  universityButtonText: string;
  universityButtonUrl: string;
}

interface UserDetails {
  first_name: string;
  last_name: string;
  username: string;
  university: string;
  email: string;
  avatar_url: string;
  is_online: boolean;
  bio: string;
  allow_direct_messages: boolean;
  allow_profile_search: boolean;
  major: string;
  linked_profile: string;
  instagram_username: string;
  x_username: string;
  // Include other user details you might have
}

interface UserContextType {
  userId: string | null;
  setUserId: (userId: string) => void;
  isLoadingUser: boolean;
  userDetails: UserDetails | null;
  setUserDetails: (details: UserDetails) => void;
  courseDetails: Course[] | null;
  setCourseDetails: (courses: Course[]) => void;
  userUniversity: UniversityData | null;
  setUserUniversity: (universityData: UniversityData) => void;
  isInitialized: boolean;
  onlineUserCount: number;
  isMobile: boolean;
  fetchAndUpdateOnlineUserCount: () => void;
  updateLastActivity: () => void;
}

interface UserProviderProps {
  children: ReactNode;
}

export const UserContext = createContext<UserContextType | null>(null);

export const UserProvider = ({ children }: UserProviderProps) => {
  const [userId, setUserId] = useState<string | null>(null);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [courseDetails, setCourseDetails] = useState<Course[] | null>(null);
  const [userUniversity, setUserUniversity] = useState<UniversityData | null>(
    null,
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const [onlineUserCount, setOnlineUserCount] = useState<number>(0);

  // Define a method to call once the session has been checked
  const checkSession = useCallback(async () => {
    setIsLoadingUser(true);
    const { data: sessionData } = await supabase.auth.getSession();

    if (sessionData?.session?.user?.id) {
      localStorage.setItem("userId", sessionData.session.user.id);
      setUserId(sessionData.session.user.id);
    } else {
      localStorage.removeItem("userId");
      setUserId(null);
    }
    setIsLoadingUser(false);
    setIsInitialized(true); // Set initialization complete
  }, []);

  // Call checkSession on mount and set up the auth state change listener
  useEffect(() => {
    checkSession();
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (event === "SIGNED_IN" && session?.user?.id) {
          setUserId(session.user.id);
          fetchUserDetails(session.user.id);
          fetchCourseDetails(session.user.id);
          fetchUserUniversity(session.user.id); // Fetch user details upon sign in
        } else if (event === "SIGNED_OUT") {
          setUserDetails(null);
          setUserId(null);
        }
      },
    );

    // Clean up function
    return () => {
      // Unsubscribe to avoid memory leaks
      if (authListener) {
        authListener.subscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (userId) {
      fetchUserDetails(userId);
      fetchCourseDetails(userId);
      fetchUserUniversity(userId);
    }
  }, [userId]);

  const fetchUserDetails = async (userId: string | null) => {
    // Return early if userId is null or undefined
    if (!userId) {
      return;
    }

    try {
      const { data, error } = await supabase
        .from("users")
        .select("*")
        .eq("id", userId)
        .single();

      if (error) {
        throw error;
      }

      setUserDetails(data);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const updateLastActivity = async () => {
    if (userId) {
      try {
        const { error } = await supabase
          .from("users")
          .update({
            last_activity_at: new Date().toISOString(),
            is_online: true,
          })
          .eq("id", userId);

        if (error) {
          throw error;
        }
      } catch (error) {
        console.error("Error updating last activity:", error);
      }
    }
  };

  const fetchCourseDetails = async (userId: string) => {
    const { data: enrollmentData, error: enrollmentError } = await supabase
      .from("student_courses")
      .select("course_id")
      .eq("student_id", userId);

    if (enrollmentError) {
      console.error("Error fetching enrollment data:", enrollmentError);
      return;
    }

    const courseIds = enrollmentData.map((enrollment) => enrollment.course_id);
    if (courseIds.length === 0) {
      setCourseDetails([]);
      return;
    }

    const { data: courses, error: coursesError } = await supabase
      .from("courses")
      .select("*")
      .in("id", courseIds);

    if (coursesError) {
      console.error("Error fetching courses:", coursesError);
    } else {
      setCourseDetails(courses);
    }
  };

  const fetchUserUniversity = async (userId: string) => {
    const { data, error } = await supabase
      .from("users")
      .select("university")
      .eq("id", userId);
    if (error) {
      console.error("Error fetching university data:", error);
      return;
    }

    const university = data?.[0]?.university;
    const universityColors: { [key: string]: string } = {
      UPRM: "#0F8F46",
      UPRRP: "#FF0000",
      UPRB: "#428bca",
      UPRAG: "#2024ac",
      UPRA: "#987414",
      UPRCA: "#253b91",
      UPRC: "#0F8F46",
      UPRH: "#85154c",
      UPRP: "#FF0000",
      UPRU: "#9b6404",
    };
    const universityData: { [key: string]: { text: string; url: string } } = {
      UPRM: {
        text: "Home UPRM",
        url: "https://home.uprm.edu/",
      },
      UPRRP: {
        text: "MiUPI",
        url: "https://miupi.uprrp.edu/",
      },
      UPRB: {
        text: "MiUPRB",
        url: "https://miuprb.uprb.edu/login.php",
      },
      UPRAG: {
        text: "MiUPRAg",
        url: "https://miuprag.uprag.edu/",
      },
      UPRA: {
        text: "MiUPRA",
        url: "https://miportal.upra.edu/site/login",
      },
      UPRCA: {
        text: "MiUPRC",
        url: "https://www.uprc.edu/",
      },
      UPRC: {
        text: "MiUPRCayey",
        url: "https://miupr.cayey.upr.edu/login.php",
      },
      UPRH: {
        text: "MiUPRH",
        url: "https://www.uprh.edu/",
      },
      UPRP: {
        text: "MiUPRPonce",
        url: "https://www.uprp.edu/wp-login.php",
      },
      UPRU: {
        text: "MiUPRUtuado",
        url: "https://www.uprutuado.edu/",
      },
    };
    const color = universityColors[university] || "#045887"; // default color
    const universityButtonText =
      universityData[university]?.text || "Home UPRM";
    const universityButtonUrl =
      universityData[university]?.url || "https://home.uprm.edu/";

    setUserUniversity({
      university: university || "Unknown University",
      color,
      universityButtonText,
      universityButtonUrl,
    });
  };
  const fetchAndUpdateOnlineUserCount = async () => {
    try {
      const { count, error } = await supabase
        .from("users")
        .select("*", { count: "exact" })
        .eq("is_online", true);

      if (error) {
        throw error;
      }
      if (count !== null) {
        setOnlineUserCount(count);
      }
    } catch (error) {
      console.error("Error updating online user count:", error);
    }
  };

  useEffect(() => {
    const delay = 1000;

    const userWatcher = supabase
      .channel("custom-users-channel")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "users" },
        () => {
          setTimeout(() => {
            fetchAndUpdateOnlineUserCount(); // Refetch the online user count after a delay
          }, delay);
        },
      )
      .subscribe();

    // Fetch the count after a delay when the component mounts
    setTimeout(() => {
      fetchAndUpdateOnlineUserCount();
    }, delay);

    return () => {
      // Remove the subscription on cleanup
      supabase.removeChannel(userWatcher);
    };
  }, []);

  const contextValue = {
    userId,
    setUserId,
    isLoadingUser,
    userDetails,
    setUserDetails,
    courseDetails,
    setCourseDetails,
    userUniversity,
    setUserUniversity,
    isInitialized,
    onlineUserCount,
    isMobile,
    fetchAndUpdateOnlineUserCount,
    updateLastActivity,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
